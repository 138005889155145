import React, { useState } from 'react';
import './Accordion.css'; // Assurez-vous d'avoir un fichier CSS pour les styles

const AccordionPage = () => {
  // State pour suivre quel accordéon est ouvert
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Tableaux de titres pour chaque section
  const titles = ["Chapitre 1", "Chapitre 2", "Chapitre 3"];

  // Fonction pour basculer l'accordéon actif
  const toggleAccordion = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  // Fonction pour générer une liste d'éléments pour chaque chapitre
  const generateItemList = () => {
    return (
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
    );
  };

  return (
    <div className="accordion">
      {titles.map((title, index) => (
        <div className={`accordion-item ${activeAccordion === index ? 'active' : ''}`} key={index}>
          <div className="accordion-title" onClick={() => toggleAccordion(index)}>
            {title}
          </div>
          <div className="accordion-content">
            {generateItemList()}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionPage;