import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleUpload } from '../../../services/teachersEmail.action';
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { ClockLoader } from 'react-spinners';
import { supabase } from '../../../supabase';
import { updateSpeciality } from '../../../services/teacher.action';
import { fetchTeacherImages } from '../../../services/teachersEmail.action';
import { useRef } from 'react';
import { sendTeacherMail } from '../../../services/teachersEmail.action';

const StepVerification = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const navigate = useNavigate();

  return (
    <div className='verification-section'>
      {step === 1 && <StepOne nextStep={nextStep} />}
      {step === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep} />}
      {step === 3 && <StepThree prevStep={prevStep} nextStep={() => {
        navigate("/auth/teacher/success");
      }} />}
    </div>
  );
};

const StepOne = ({ nextStep }) => {
  const [selectedButton, setSelectedButton] = useState(() => {
    // Retrieve the stored value from sessionStorage on initial render
    return parseInt(sessionStorage.getItem('selectedButtonIndex'), 10);
  });
  const [selectedLabel, setSelectedLabel] = useState(() => {
    // Retrieve the stored value from sessionStorage on initial render
    return sessionStorage.getItem('selectedLabel') || '';
  });

  const handleYears = (e) =>{
    sessionStorage.setItem("YearsExperience",e.target.value)
  }

  const handleButtonClick = (index, label) => {
    if (selectedButton === index) {
      setSelectedButton(null);
      setSelectedLabel('');
      sessionStorage.removeItem('selectedButtonIndex');
      sessionStorage.removeItem('selectedLabel'); // Remove the stored value
    } else {
      setSelectedButton(index);
      setSelectedLabel(label);
      sessionStorage.setItem('selectedButtonIndex', index);
      sessionStorage.setItem('selectedLabel', label); // Store the selected label in sessionStorage
    }
  };

  const buttons = [
    { id: 1, icon: "fas fa-code", label: "development" },
    { id: 2, icon: "fas fa-pen", label: "Designer UX/UI" },
    { id: 3, icon: "fa-solid fa-pen-nib", label: "Graphiste" },
    { id: 4, icon: "fa-solid fa-laptop", label: "Consultant en informatique" },
    { id: 5, icon: "fa-solid fa-chart-area", label: "Analyste fonctionnel" },
    { id: 6, icon: "fa-solid fa-sitemap", label: "Architecte d'entreprise" },
    { id: 7, icon: "fa-brands fa-wpbeginner", label: "Ingénieur en plateformes" },
    { id: 8, icon: "fa-solid fa-vr-cardboard", label: "Spécialiste en virtualisation" },
    { id: 9, icon: "fa-solid fa-phone", label: "Ingénieur VoIP" },
    { id: 10, icon: "fa-brands fa-windows", label: "Développeur de systèmes d'exploitation" },
    { id: 11, icon: "fa-solid fa-network-wired", label: "Spécialiste en réseaux sans fil" },
    { id: 12, icon: "fas fa-chart-line", label: "marketing" },
    { id: 13, icon: "fas fa-music", label: "music" },
    { id: 14, icon: "fas fa-camera", label: "photography" },
    { id: 15, icon: "fas fa-cog", label: "software" },
    { id: 16, icon: "fas fa-vial", label: "science" }
  ];

  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }} className='verification'>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 5, marginTop: 5 }}>
        <h1>Vérification</h1>
        <h1>Etape <span style={{ color: '#007bff' }}>1</span>/3</h1>
      </div>
      <div style={{ backgroundColor: "var(--white)", marginTop: 20, padding: 20, borderRadius: 10 }}>
        <div className="box">
          <h3 className='title'>Quelle Est Le/Les Domaine(S) Dans Lesquelles Vous Souhaitez Donner Des Cours ?</h3>
          <div className="flex">
            {buttons.map((button, index) => (
              <button
                key={button.id}
                onClick={() => handleButtonClick(index, button.label)}
                style={selectedButton === index ? { backgroundColor: "var(--main-color)" } : {}}
              >
                <i className={button.icon} style={selectedButton === index ? { color: "var(--white)" } : {}}></i>
                <span style={selectedButton === index ? { color: "var(--white)" } : {}}>{button.label}</span>
                
              </button>
            ))}
          </div>
          <div style={{ display: "flex" }}>
            <h3 className='title'>Quelle Est Votre Nombre D’années D’expérience ?</h3>
            <input type="number" defaultValue="2" min="1" onChange={handleYears} style={{ width: 50, marginLeft: 15, color: "var(--white)", backgroundColor: "var(--main-color)" }} />
          </div>
          <button onClick={nextStep} className="btn-next">Suivant</button>
        </div>
      </div>
    </div>
  );
};


const StepTwo = ({ nextStep, prevStep }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Charger les images depuis le sessionStorage lors du montage du composant
    const storedImages = JSON.parse(sessionStorage.getItem('images')) || [];
    setImages(storedImages);

    // Effacer le sessionStorage avant que l'utilisateur quitte la page
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('images');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const portfolioChange = (e) =>{
    sessionStorage.setItem("portfolioLink",e.target.value)
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file && file.type.startsWith('image/')) {
      if (images.length < 5) {
        const newImages = [...images, URL.createObjectURL(file)];
        setImages(newImages);
        sessionStorage.setItem('images', JSON.stringify(newImages));
      } else {
        alert('Vous ne pouvez sélectionner que 5 images.');
      }
    } else {
      alert('Veuillez sélectionner un fichier image.');
    }
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    sessionStorage.setItem('images', JSON.stringify(updatedImages));
  };

  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }} className='verification'>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 5, marginTop: 5 }}>
        <h1>Vérification</h1>
        <h1>Etape <span style={{ color: '#007bff' }}>2</span>/3</h1>
      </div>
      <div style={{ backgroundColor: "var(--white)", marginTop: 20, padding: 20, borderRadius: 10 }}>
        <div className="box">
          <h3 className='title'>Veuillez Renseigner Les Différents Documents Qui Prouvent Vos Compétences</h3>
          <label style={{ fontSize: 13 }}>Lien vers votre portfolio :</label>
          <input type="text" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid var(--black)", marginBottom: 5, marginTop: 5, width: "100%", paddingBottom: 5 }} onChange={portfolioChange} />
          <h3 className='title'>Ajoutez Vos Certifications / Diplomes Dans Vos Domaines De Compétence</h3>
          <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="cover-photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', height: '100px', border: '1px dashed #ccc', cursor: 'pointer', borderRadius: '5px', flexDirection: "column" }}>
                <span style={{ fontSize: 16, color: "var(--black)" }}>Ajouter</span>
                <div className='fas fa-plus' style={{ fontSize: 20, fontWeight: "bold", color: "var(--black)" }}></div>
              </label>
              <input type="file" id="cover-photo" accept="image/*" onChange={handleImage} style={{ display: 'none' }} />
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative', margin: '5px' }}>
                <img className='imgStepTwo'
                 onClick={() => removeImage(index)}
                 style={{
                   position: 'absolute',
                   top: '5px',
                   right: '5px',
                   background: 'rgba(252, 3, 69, 0.8)',
                   border: 'none',
                   borderRadius: '50%',
                   cursor: 'pointer',
                   display: 'none',
                   justifyContent: 'center',
                   alignItems: 'center',
                   fontSize: '20px'
                 }}
                  src={image}
                  alt={`image-${index}`}
                  style={{ width: "100px", height: "100px", objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button onClick={prevStep} className="btn-prev">Précédent</button>
            <button onClick={nextStep} className="btn-next">Suivant</button>
          </div>
        </div>
      </div>
    </div>
  );
};


const StepThree = ({ prevStep, nextStep }) =>  {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);
  const form = useRef();
  const userId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id;

  useEffect(() => {

    // Charger les images depuis le sessionStorage lors du montage du composant
    const storedImages = JSON.parse(sessionStorage.getItem('images3')) || [];
    setImages(storedImages);

    // Effacer le sessionStorage avant que l'utilisateur quitte la page
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('images3');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      if (images.length < 2) {
        const newImages = [...images, URL.createObjectURL(file)];
        setImages(newImages);
        sessionStorage.setItem('images3', JSON.stringify(newImages));
      } else {
        alert('Vous ne pouvez sélectionner que 2 images.');
      }
    } else {
      alert('Veuillez sélectionner un fichier image.');
    }
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    sessionStorage.setItem('images3', JSON.stringify(updatedImages));
  };

  const getImageUrls = async () => {
    const { data, error } = await supabase
      .from('imagesDocument')
      .select('url')
      .eq('user_id', userId.toString());

    if (error) {
      console.error('Error fetching images:', error);
      return [];
    }

    return data
  };

  const sendTeacherMail = async(user_Id,teacherName, speciality, yearsExperience, imageUrls) =>{
    if (!imageUrls || imageUrls.length === 0) {
      console.error('No image URLs to send.');
      return;
    }
    let i = 1
    const imageTags = imageUrls.map(item => ` doc ${i++} ${item.url}`).join('\n');

    const templateParams = {
      user_id: user_Id,
      teacher_Name: teacherName,
      speciality,
      Nombre: yearsExperience,
      images: imageTags,
    };
    emailjs.send(
      "service_81eu0fv",
        "template_ypoi91x",
        templateParams,
        {
          publicKey: "31wfPCNpzsQp4fDsI",
        })
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log('FAILED...', error.text);
          },  
        )
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const userImages = [...JSON.parse(sessionStorage.getItem("images3")), ...JSON.parse(sessionStorage.getItem("images"))];
    const speciality = sessionStorage.getItem("selectedLabel");
    const yearsExperience = sessionStorage.getItem("YearsExperience");

    await updateSpeciality(speciality,userId)
    // Upload images
    for (const image of userImages) {
      await handleUpload(userId, image); // Appel à la fonction d'upload
    }

    const imageUrls = await getImageUrls();
    // Send email
    console.log(imageUrls)
    await sendTeacherMail(userId.toString(),JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.user_metadata.name,speciality,yearsExperience,imageUrls);
    setIsLoading(false)
    setShowModal(true);
  };

  return (
    !isLoading ?(
      <div style={{ marginLeft: "10px", marginRight: "10px"}} className='verification'>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 5, marginTop: 5}}>
          <h1>Vérification</h1>
          <h1>Etape <span style={{ color: '#007bff' }}>3</span>/3</h1>
      </div>
      <div style={{backgroundColor: "var(--white)", marginTop: 20, padding:20, borderRadius: 10}}>
        <form className="box" ref={form} onSubmit={handleSubmit}>
          <h3 className='title'>Verification De Votre Identité : ( Veillez Ajouter Les Photos Recto/Verso D’une Pièce Qui Vous Identifie )</h3>
            <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="photo1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', height: '100px', border: '1px dashed #ccc', cursor: 'pointer', borderRadius: '5px', flexDirection: "column" }}>
                  <span style={{fontSize: 16, color: "var(--black)"}}>Recto/Verso</span>
                  <div className='fas fa-plus' style={{fontSize: 20, fontWeight: "bold", color: "var(--black)"}}></div>
                </label>
                <input type="file" id="photo1" accept="image/*" onChange={handleImage} style={{ display: 'none',height:'100px',width:'100px', }} />
              </div>
              <div>
                <h2 style={{color:"red", display: "flex", alignItems: "center"}}><span style={{width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'white', border: '2px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><span style={{ color: 'red', fontSize: 40, fontWeight: 'bold'}}>!</span></span><span style={{marginLeft: 10}}>Notez bien :</span></h2>              <ul>
                  <li style={{color: "var(--black)", fontSize:10}}>Les documents que vous allez soumettre doivent être bien lisibles, et filmés dans un cadre conforme</li>
                  <li style={{color: "var(--black)", fontSize:10}}>Rassurez-vous que les informations que vous avez entré sont correctes, sinon votre candidature sera rejetée</li>
                  <li style={{color: "var(--black)", fontSize:10}}>En cliquant sur soumettre, vous acceptez avoir lu notre politique de confidentialité et de protection des utilisateurs</li>
                </ul>
              </div> 
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative', margin: '5px' }}>
                <img
                onClick={() => removeImage(index)}
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  background: 'rgba(252, 3, 69, 0.8)',
                  border: 'none',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px'}}
                  src={image}
                  alt={`image-${index}`}
                  style={{ width: "100px", height: "100px", objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <button onClick={prevStep} className="btn-prev">Precedent</button>
                  <input type="submit" value="Send" className="btn-next" name="submit"/>
                </div>
              </form>
      </div>
      {showModal && <ConfirmationModal onClose={() => setShowModal(false)} onConfirm={nextStep} />}
    </div>
    ):(
      <ClockLoader
        color={'#2196F3'}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
  );
} 

const ConfirmationModal = ({ onClose, onConfirm }) => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right'
  }}>
    <div style={{
      backgroundColor: 'white',
      padding: '20px',
      marginRight: 150,
      borderRadius: '10px',
      textAlign: 'center',
    }}>
      <div style={{
        width: '60px',
        height: '60px',
        margin: '0 auto 20px',
        borderRadius: '50%',
        border: '2px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <span style={{ color: '#007bff', fontSize: '40px', fontWeight: 'bold' }}>✓</span>
      </div>
      <p style={{color: "var(--black)"}}>Nous Avons Bien Reçu Votre Demande . Et Nous Allons Vous Envoyer L'état De Votre Demande Par Mail Au Trop Tard Dans 48 H</p>
      <button onClick={onConfirm} style={{
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '20px'
      }}>Ok</button>
    </div>
  </div>
);

export default StepVerification;

