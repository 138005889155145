import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import Home from './Home';
import Courses from './Courses';
import CourseDetails from './CoursesDetails';
import Lesson from './Lesson';
import Teachers from './Teachers';
import Profile from './Profile';
import Update from './Update';
import TeacherProfile from './TeacherProfile';
import About from './About/auth';
import Contact from './Contact/auth';
import pic_1 from "../assets/images/pic-1.jpg"
import './../App.css'
import Error from './Error/auth';
import Search from './Search';
import { logout } from '../services/auth/Logout';
import { getUserSession,getUserStatus } from '../services/user.action';
import AccordionPage from './../components/Accordion';
import Success from './SuccessFinished';
import TeacherAuthPage from './teacher-pages';
import { getUserImage } from '../services/user.action';

const MainAuthPage = () => {
  
  /** Pour les interactions avec la navbar */
  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn');
    let body = document.body;
    let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    if (toggleBtn) {
      toggleBtn.addEventListener('click', () => {
        darkMode = localStorage.getItem('dark-mode');
        if (darkMode === 'disabled') {
          enableDarkMode();
        } else {
          disableDarkMode();
        }
      });
    }

    let profile = document.querySelector('.header .flex .profile');
    let userBtn = document.querySelector('#user-btn');
    if (userBtn) {
      userBtn.addEventListener('click', () => {
        if (profile) {
          profile.classList.toggle('active');
        }
        let search = document.querySelector('.header .flex .search-form');
        if (search) {
          search.classList.remove('active');
        }
      });
    }

    let search = document.querySelector('.header .flex .search-form');
    let searchBtn = document.querySelector('#search-btn');
    if (searchBtn) {
      searchBtn.addEventListener('click', () => {
        if (search) {
          search.classList.toggle('active');
        }
        if (profile) {
          profile.classList.remove('active');
        }
      });
    }

    let sideBar = document.querySelector('.side-bar');
    let menuBtn = document.querySelector('#menu-btn');
    let closeBtn = document.querySelector('#close-btn');
    if (menuBtn) {
      menuBtn.addEventListener('click', () => {
        if (sideBar) {
          sideBar.classList.toggle('active');
          if (sideBar && window.innerWidth < 1200) {
            // Masquer toutes les images lorsque la barre latérale est activée
            let allImages = document.querySelectorAll('.thumb, video');
            allImages.forEach(image => {
              image.style.display = sideBar.classList.contains('active') ? 'none' : '';
            });
          }
        }
        if (body) {
          body.classList.toggle('active');
        }
        if (main) {
          main.classList.toggle('active');
        }
      });
    }
    
    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        if (sideBar) {
          sideBar.classList.remove('active');
        }
        if (body) {
          body.classList.remove('active');
        }
        if (main) {
          main.classList.remove('active');
        }
      });
    }

    window.addEventListener('scroll', () => {
      if (profile) {
        profile.classList.remove('active');
      }
      if (search) {
        search.classList.remove('active');
      }
      if (sideBar && window.innerWidth < 1200) {
        sideBar.classList.remove('active');
        if (body) {
          body.classList.remove('active');
        }
        if (main) {
          main.classList.remove('active');
        }
        let allImages = document.querySelectorAll('.thumb, video');
          allImages.forEach(image => {
            image.style.display = sideBar.classList.contains('active') ? 'none' : '';
          });
      }
    });

    return () => {
      // Nettoyage des écouteurs d'événements lors du démontage du composant
      if (toggleBtn) {
        toggleBtn.removeEventListener('click', () => {
          darkMode = localStorage.getItem('dark-mode');
          if (darkMode === 'disabled') {
            enableDarkMode();
          } else {
            disableDarkMode();
          }
        });
      }
      if (userBtn) {
        userBtn.removeEventListener('click', () => {
          if (profile) {
            profile.classList.toggle('active');
          }
          let search = document.querySelector('.header .flex .search-form');
          if (search) {
            search.classList.remove('active');
          }
        });
      }
      if (searchBtn) {
        searchBtn.removeEventListener('click', () => {
          if (search) {
            search.classList.toggle('active');
          }
          if (profile) {
            profile.classList.remove('active');
          }
        });
      }
      if (menuBtn) {
        menuBtn.removeEventListener('click', () => {
          if (sideBar) {
            sideBar.classList.toggle('active');
            if (sideBar && window.innerWidth < 1200) {
              // Masquer toutes les images lorsque la barre latérale est activée
              let allImages = document.querySelectorAll('.thumb, video');
              allImages.forEach(image => {
                image.style.display = sideBar.classList.contains('active') ? 'none' : '';
              });
            }
          }
          if (body) {
            body.classList.toggle('active');
          }
          if (main) {
            main.classList.toggle('active');
          }
        });
      }
      if (closeBtn) {
        closeBtn.removeEventListener('click', () => {
          if (sideBar) {
            sideBar.classList.remove('active');
          }
          if (body) {
            body.classList.remove('active');
          }
          if (main) {
            main.classList.remove('active');
          }
        });
      }
      window.removeEventListener('scroll', () => {
        if (profile) {
          profile.classList.remove('active');
        }
        if (search) {
          search.classList.remove('active');
        }
        if (sideBar && window.innerWidth < 1200) {
          sideBar.classList.remove('active');
          if (body) {
            body.classList.remove('active');
          }
          if (main) {
            main.classList.remove('active');
          }
          let allImages = document.querySelectorAll('.thumb, video');
            allImages.forEach(image => {
              image.style.display = sideBar.classList.contains('active') ? 'none' : '';
            });
        }
      });
    };
  }, []);
  /**  Fin */

  const [showSearchForm, setShowSearchForm] = useState(true);
  const [userSession, setUserSession] = useState({});
  const [userStatus,setUserStatus] = useState("")
  const [userImage,setUserImage] = useState("")
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/auth/search') {
      setShowSearchForm(false);
    } else {
      setShowSearchForm(true);
    }
  }, [location]);

  useEffect(() => {
    // Fonction asynchrone pour récupérer les informations de l'utilisateur et les stocker dans le state
    const fetchUserSession = async () => {
      const userData = await getUserSession();
      // Mettre à jour le state avec les informations de l'utilisateur
      setUserSession(userData);
    };

    const fetchUserStatus = async() =>{
      const userStatus = await getUserStatus();
      setUserStatus(userStatus)
    }
    // Appeler la fonction fetchUserSession au montage du composant
    fetchUserSession().then(
      fetchUserStatus()
    )
  }, []);

  useEffect(() =>{
    const getImageUser = async() =>{
      const image = await getUserImage()
      setUserImage(image)
    }

    getImageUser()
  },[])

  let userRole = localStorage.getItem('userRole');

  const handleLogout = async () => {
    const resultRequest = await logout();
    if (resultRequest.success) {
      navigate('/');
      window.location.reload();
    }
  }

  function accessAllCourses() {
    sessionStorage.setItem("Choose Course", "Our Courses");
  }

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const getHomePath = () => {
    return userRole === 'teacher' ? '/auth/teacher/dashboard' : '/auth/home';
  };


  const homePath = getHomePath();

  return (
    <main className="main">
      <header className="header">
        <section className="flex">
          <Link to={'/auth/home'} className="logo" translate='no'>ITESTUDY</Link>
          {
            showSearchForm &&
            <form action="" className="search-form">
              <input required type="text" name="search_box"  placeholder="search courses..." maxLength={100} minLength={1}/>
              <button type="submit" className="fas fa-search" onClick={() => { navigate('/auth/search') }}></button>
            </form>
          }

          <div className="icons">
            <div id="menu-btn" className="fas fa-bars"></div>
            <div id="search-btn" className="fas fa-search"></div>
            <div id="user-btn" className="fas fa-user"></div>
            <div id="toggle-btn" className="fas fa-sun"></div>
          </div>

          <div className="profile">
            <img src={userImage || userSession['image']} className="image" alt="" />
            <h3 className="name">{userSession['username'] ||JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.user_metadata.name}</h3>
            <p className="role">{userStatus.toString()}</p>
            <Link to={'/auth/profile'} className="btn">view profile</Link>
            <div className="flex-btn">
              <button className="option-btn" onClick={handleLogout} >logout</button>
            </div>
          </div>
        </section>
      </header>   

      <div className="side-bar">
        <div id="close-btn">
          <i className="fas fa-times"></i>
        </div>

        <div className="profile">
          <img src={userImage || userSession['image']} className="image" alt="" />
          <h3 className="name">{userSession['username'] ||JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.user_metadata.name}</h3>
          <p className="role">{userStatus.toString()}</p>
          <Link to={'/auth/profile'} className="btn">view profile</Link>
        </div>

        <nav className="navbar">
          <Link to={homePath} className={isActive(homePath)}>
            <i className="fas fa-home"></i><span>home</span>
          </Link>
          <Link to={'/auth/about'} className={isActive('/auth/about')}>
            <i className="fas fa-question"></i><span>about</span>
          </Link>
          <Link to={'/auth/courses'} className={isActive('/auth/courses')} onClick={() => accessAllCourses()}>
            <i className="fas fa-graduation-cap"></i><span>courses</span>
          </Link>
          <Link to={"/auth/teachers"} className={isActive('/auth/teachers')}>
            <i className="fas fa-chalkboard-user"></i><span>teachers</span>
          </Link>
          <Link to={"/auth/contact"} className={isActive('/auth/contact')}>
            <i className="fas fa-headset"></i><span>contact us</span>
          </Link>
        </nav>
      </div>
      
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/search' element={<Search />} />
        <Route path='/course/details/:id' element={<CourseDetails />} />
        <Route path='/course/details/chapter/details' element={<AccordionPage />} />
        <Route path='/course/details/chapter/details/lesson' element={<Lesson />} />
        <Route path='/course/success' element={<Success />} />
        <Route path='/teachers' element={<Teachers />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/profile/update' element={<Update />} />
        <Route path='/teacher/profile' element={<TeacherProfile />} />
        <Route path='/teacher/*' element={<TeacherAuthPage />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/*' element={<Error />} />
      </Routes>

      <br /><br /><br /><br /><br />

      <footer className="footer">
        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!
      </footer>
    </main>
  );
}

export default MainAuthPage;
