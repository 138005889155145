import React from 'react';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { CardCourse, getAllCourse } from '../../services/course.action';
import { commentCours, fetchSavedCourses, likeCours } from '../../services/user.action';
import { getUserStatus } from '../../services/user.action';


const Home = () => {
  const [allCourse,setAllCourse] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [saveCourse,setSaveCourse] = useState([])
  const [likeCourse,setLikeCOurse] = useState([])
  const [userStatus,setUserStatus] = useState("")
  const [commentCourse,setCommentCourse] = useState([])

  useEffect(() =>{
    async function fetchData() {
    try{
        setIsLoading(true)
        const cours = await getAllCourse()
        const Scours = await fetchSavedCourses()
        const Lcours = await likeCours()
        const Ccours = await commentCours()
        if(cours){
          setIsLoading(false)
          setSaveCourse(Scours)
          setLikeCOurse(Lcours)
          setCommentCourse(Ccours)
          setAllCourse(cours)
          console.log(Ccours)

          
        }
        else{
          setIsLoading(false)
          console.log(cours.Course)
        }
      
    }
    catch(error){
        console.error(error)
    }
    
  }

  const userStatus = async() =>{
    const status = await getUserStatus()
    setUserStatus(status)
  }

  fetchData().then(
    userStatus()
  )
},[]);

  function accessLikeCourses(){
    sessionStorage.setItem("Choose Course","Likes Courses")
  }
  function accessCommentCourses(){
    sessionStorage.setItem("Choose Course","Comment Courses")
  }
  function accessSavedCourses(){
    sessionStorage.setItem("Choose Course","Saved Courses")
  }
  function accessAllCourses(){
    sessionStorage.setItem("Choose Course","Our Courses")
  }
  function tagCours(value){
    sessionStorage.setItem("Choose Course",value)
  }

  return (
    !isLoading ? (
    <>

      <section className="home-grid">

        <h1 className="heading">quick options</h1>

        <div className="box-container">

            <div className="box">
              <h3 className="title">likes and comments</h3>
              <p className="likes">total likes : <span>{likeCourse.length ? likeCourse.length : 0}</span></p>
              <Link to={'/auth/courses'} className="inline-btn" onClick={() => {accessLikeCourses()}}>view likes</Link>
              <p className="likes">total comments : <span>{commentCourse.length ? commentCourse.length : 0}</span></p>
              <Link to={'/auth/courses'} className="inline-btn" onClick={() => {accessCommentCourses()}}>view comments</Link>
              <p className="likes">saved courses : <span>{saveCourse.length ? saveCourse.length : 0}</span></p>
              <Link to={'/auth/courses'} className="inline-btn" onClick={() => {accessSavedCourses()}}>view courses</Link>
            </div>

            <div className="box">
              <h3 className="title">top categories</h3>
              <div className="flex">
                  <Link to={'/auth/courses'} onClick={() => tagCours('development')}><i className="fas fa-code"></i><span>development</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('business')}><i className="fas fa-chart-simple"></i><span>business</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('design')}><i className="fas fa-pen"></i><span>design</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('marketing')}><i className="fas fa-chart-line"></i><span>marketing</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('music')}><i className="fas fa-music"></i><span>music</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('photography')}><i className="fas fa-camera"></i><span>photography</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('software')}><i className="fas fa-cog"></i><span>software</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('science')}><i className="fas fa-vial"></i><span>science</span></Link>
              </div>
            </div>

            <div className="box">
              <h3 className="title">popular topics</h3>
              <div className="flex">
                  <Link to={'/auth/courses'} onClick={() => tagCours('HTML')}><i className="fab fa-html5"></i><span>HTML</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('CSS')}><i className="fab fa-css3"></i><span>CSS</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('javascript')}><i className="fab fa-js"></i><span>javascript</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('react')}><i className="fab fa-react"></i><span>react</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('PHP')}><i className="fab fa-php"></i><span>PHP</span></Link>
                  <Link to={'/auth/courses'} onClick={() => tagCours('bootstrap')}><i className="fab fa-bootstrap"></i><span>bootstrap</span></Link>
              </div>
            </div>

            <div className="box">
              <h3 className="title">become a teacher</h3>
              <p className="tutor">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perspiciatis, nam?</p>
              {
                userStatus === 'Student' ?(
                  <Link to="/auth/teacher/verification" className="inline-btn" >get started</Link>
                ) : userStatus === 'Teacher' ?(
                  <Link to="/auth/teacher/dashboard" className="inline-btn" >go to dashboard</Link>
                ) :(
                  <Link to="/auth/teacher/verification" className="inline-btn" >get started</Link>
                )
              }
            </div>
        </div>

      </section>



      <section className="courses">

        <h1 className="heading">our courses</h1>

        <div className="box-container">
        {
              allCourse && (
                <>
                  {
                    allCourse.map(
                      cours =>(
                        <>
                          <CardCourse
                            key={cours.cour.CourseID}
                            id={cours.cour.CourseID}
                            teacherName={cours.Name}
                            teacherImg={cours.Thumbnail}
                            type={cours.cour.Type}
                            price={cours.cour.Price}
                            teacherSpecialization={cours.Specialization}
                            notation = {cours.courseRating}
                            date={cours.cour.created_at}
                            image={cours.cour.Thumbnail}
                            title={cours.cour.Title}
                          />
                        </>
                      )
                    )
                  }
                </>
              )
        }
            

            
        </div>

        <div className="more-btn">
            <Link to={'/auth/courses'} onClick={() => accessAllCourses()} className="inline-option-btn">view all courses</Link>
        </div>

      </section>
    </>
                ): (
                  <ClipLoader
                    color={'#2196F3'}
                    loading={isLoading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  )
  );
}

export default Home;
