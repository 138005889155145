import React, { useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepTwoUpdate from '../UpdateCourse/StepTwoUpdate';
import StepOneUpdate from '../UpdateCourse/StepOneUpdate';
import StepThreeUpdate from '../UpdateCourse/StepThreeUpdate';
import StepFourUpdate from '../UpdateCourse/StepFourUpdate';
import StepFiveUpdate from '../UpdateCourse/StepFiveUpdate';

const AddCourseSteps = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
          {
            sessionStorage.getItem('manageMode') === "add" ? (
              <StepOne nextStep={nextStep} />
            ):(
              <StepOneUpdate nextStep={nextStep}/>
            )
          }
          </> 
      )
      case 2:
        return (
          <>
          {
            sessionStorage.getItem('manageMode') === "add" ? (
              <StepTwo nextStep={nextStep} prevStep={prevStep} />
            ):(
              <StepTwoUpdate nextStep={nextStep} prevStep={prevStep} />
            )
          }
          </>
        ) 
      case 3:
        return (
          <>
            {
              sessionStorage.getItem('manageMode') === "add" ? (
                <StepThree nextStep={nextStep} prevStep={prevStep} />
              ):(
                <StepThreeUpdate nextStep={nextStep} prevStep={prevStep} />
              )
            }
          </>
        )
      case 4:
        return (
          <>
            {
              sessionStorage.getItem('manageMode') === "add" ? (
                <StepFour nextStep={nextStep} prevStep={prevStep} />
              ):(
                <StepFourUpdate nextStep={nextStep} prevStep={prevStep} />
              )
            }
          </>
        )
      case 5:
        console.log(currentStep)
        return (
          <>
            {
              sessionStorage.getItem('manageMode') === "add" ? (
                <StepFive prevStep={prevStep} />
              ):(
                <StepFiveUpdate prevStep={prevStep} />
              )
            }
          </>
        )
      default:
        return (
          <>
          {
            sessionStorage.getItem('manageMode') === "add" ? (
              <StepOne nextStep={nextStep} />
            ):(
              <StepOneUpdate nextStep={nextStep}/>
            )
          }
          </> 
      )
    }
  };

  return (
    <div className="add-course-steps-container" style={{ padding: '20px', border: '1px solid #ddd' }}>
      {renderStep()}
    </div>
  );
};

export default AddCourseSteps;
