import Cookies from 'js-cookie';
import { supabase } from '../../supabase';


// Fonction pour ajouter un utilisateur à la table User
// Fonction pour ajouter un utilisateur à la table User
export const createUser = async (username, email, password) => {
  try {
    const { user, session, error: authError } = await supabase.auth.signUp({
      email : email.toString(),
      password: password.toString(),
      options:{
        data:{
          name: username.toString()
        }
      }
    })

    if (authError) {
      return { success: false, error: authError.message };
    }

    return { success: true, user, session }
  } catch (error) {
    return { success: false, error: error.message }
  }
};


export const loginUser = async (Email, password, remember) => {
  try {
    const { user, session, error: authError } = await supabase.auth.signInWithPassword({
      email: Email.toString(),
      password: password.toString(),
    });

    if (authError) {
      console.error('Erreur lors de la connexion dans Supabase Auth :', authError.message);
      return { success: false, error: authError.message };
    }

    const userResult = await getUserByEmail(Email);
    console.log('Résultat de la récupération de l\'utilisateur :', userResult);
    // localStorage.setItem('userAuthEmailPasswordData', JSON.stringify(userResult.user));

    if (userResult.success) {
      const { username, email } = userResult.user;

      // Stockez les informations de l'utilisateur dans la session
      const userSession = { username, email };
      sessionStorage.setItem('userSession', JSON.stringify(userSession));

      console.log('Utilisateur connecté avec succès :', userSession);
    } else {
      console.error('Erreur lors de la récupération des détails de l\'utilisateur :', userResult.error);
    }


    // Stockage de la session dans un cookie sécurisé si l'option "Remember" est activée
    if (remember) {
      Cookies.set('supabase_session', JSON.stringify({ Email, password }), { secure: true, sameSite: 'Strict' });
    }

    return { success: true, user, session };
  } catch (error) {
    console.error('Erreur lors de la connexion :', error.message);
    return { success: false, error: error.message };
  }
};

export const getUserByEmail = async (email) => {
  try {
    // Interrogez la table User avec l'email fourni
    const { data, error } = await supabase
      .from('users')
      .select()
      .eq('email', email);

    if (error) {
      console.error('Erreur lors de la récupération de l\'utilisateur :', error.message);
      return { success: false, error: error.message };
    }

    // Si l'utilisateur est trouvé, renvoyez-le
    if (data && data.length > 0) {
      console.log(data[0])
      return { success: true, user: data[0] };  // Utilisez data[0] pour récupérer le premier utilisateur
    } else {
      // Si aucun utilisateur n'est trouvé avec cet email
      return { success: false, error: 'Aucun utilisateur trouvé avec cet email.' };
    }
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'utilisateur :', error.message);
    return { success: false, error: error.message };
  }
};


