import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {
  useEffect(() => {
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');
    const enableDarkMode = () => {
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }
    if (darkMode === 'enabled') {
      enableDarkMode();
    }
  });
  const navigate = useNavigate()
  return (
    <>
      <section className="form-container">

        <form action="" encType="multipart/form-data">
          <h3>Reset Your Password</h3>
          <p>your new password <span>*</span></p>
          <input type="password" name="password" placeholder="enter your new password" required maxLength={20} className="box" />
          <p>confirm your new password <span>*</span></p>
          <input type="password" name="Confirmpassword" placeholder="confirm your new password" required maxLength={20} className="box" />
          <input type="submit" value="Update Password" name="submit" className="btn" onClick={() => { navigate('/login') }} />
        </form>
      </section>

      <footer className="footer" style={{ position: 'absolute' }}>

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

      </footer>
    </>
  );
}

export default UpdatePassword;
