import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { signInWithGithub } from "../../services/auth/Github";
import { signInWithGoogle } from "../../services/auth/Google";
import { createUser } from "../../services/auth/EmailAndPassword";
import { ClipLoader } from "react-spinners";

const Register = () => {
  // State pour les champs du formulaire, isLoading et error
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  // Utilisation de useNavigate pour la navigation
  const navigate = useNavigate();

  const backNavigate = () => {
    navigate(-1)
  }

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Vérification des champs
    // Vérifier si les mots de passe correspondent
    if (password !== repeatPassword) {
      setPasswordMismatch(true);
      setIsLoading(false);
      return;
    }

    // Vérifier si l'email est valide
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Enter a valid email');
      setIsLoading(false);
      return;
    }

    // Vérifier si le mot de passe est suffisamment fort
    // (par exemple, s'il a au moins 8 caractères, une lettre majuscule, une lettre minuscule et un chiffre)
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('The password must contain at least 8 characters, an uppercase letter, a lowercase letter and a number.');
      setIsLoading(false);
      return;
    }

    // Vérifier si les conditions d'utilisation sont acceptées
    if (!termsChecked) {
      setError('Please accept terms condition.');
      setIsLoading(false);
      return;
    }
    
    // Réinitialiser les erreurs
    setPasswordMismatch(false);
    setError('');

    // Logique d'enregistrement ici
    const registrationResult = await createUser(username, email, password);

    if (registrationResult.success) {
      setIsLoading(false);
      navigate('/register/success');
    } else {
      setIsLoading(false);
      setError(registrationResult.error);
    }
  };

  // Fonction pour gérer l'authentification avec Google
  const handleGoogleAuth = async () => {
    setIsLoading(true);
    const loginResult = await signInWithGoogle();

    if (!loginResult.success) {
      setError(loginResult.error);
      setIsLoading(false);
    }
  };

  // Fonction pour gérer l'authentification avec Github
  const handleGithubAuth = async () => {
    setIsLoading(true);
    const loginResult = await signInWithGithub();

    if (!loginResult.success) {
      setError(loginResult.error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn-login');
    let body = document.body;
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    toggleBtn.addEventListener('click', () => {
      darkMode = localStorage.getItem('dark-mode');
      if (darkMode === 'disabled') {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });
  }, []);

  // Rendu du composant
  return (
    !isLoading ? (
      <>
        <header className="header">
          <section className="flex">
            <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>
            <div style={{ fontSize: 40 }} id="auth-nav-icon" className="fas fa-close" onClick={backNavigate}></div>
          </section>
        </header>
  
        <section className="form-container">
          <form onSubmit={handleSubmit}>
            <h3>Create Your Account</h3>
            {error && (
              <div className="alert">
                <p>{error}</p>
              </div>
            )}
            <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
              <GoogleLoginButton onClick={() => handleGoogleAuth()} text="Google" align="center" style={{}} />
              <GithubLoginButton onClick={() => handleGithubAuth()} text="Github" align="center" style={{}} />
            </div>
            <div className="connect-with">
              <div className="horizontal-line"></div>
              <p style={{ fontSize: 20 }}>Or Register With</p>
              <div className="horizontal-line"></div>
            </div>
            <p>your username <span>*</span></p>
            <input type="text" name="username" placeholder="enter your username" value={username} onChange={(e) => setUsername(e.target.value)} required maxLength={50} className={`box`} />
            <p>your email <span>*</span></p>
            <input type="email" name="email" placeholder="enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required maxLength={50} className={`box`} />
            <div>
              <div>
                <p>your password <span>*</span></p>
                <input type="password" name="pass" placeholder="enter your password" onChange={(e) => setPassword(e.target.value)} required maxLength={20} className={`box`} style={{}} />
              </div>
              <div>
                <p>confirm your password <span>*</span></p>
                {passwordMismatch && <p style={{color: 'red'}}>Passwords not match!</p>}
                <input type="password" name="repeatPass" onChange={(e) => setRepeatPassword(e.target.value)} placeholder="confirm your password" required maxLength={20} className={`box`} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }} className="terms-checkbox">
                <input type="checkbox" name="terms" id="terms" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} required />
                <label htmlFor="terms">I have read and accept the Terms of <span>Service & Privacy Policy *</span></label>
              </div>
              <Link to={'/login'} id="forgot-link">Have an account?</Link>
            </div>
            <input type="submit" value="Register" name="submit" className="btn" />
          </form>
          <div id="toggle-btn-login" className="fas fa-sun"></div>
        </section>
  
        <footer className="footer">
          &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!
        </footer>
      </>
    ) : (
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
        <ClipLoader
          color={'#2196F3'}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> 
      </div>
    )
  );
}

export default Register;
