import React, { Suspense, useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'react-quill/dist/quill.snow.css';
import { FadeLoader } from 'react-spinners';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Modal from '../../components/Modal';
import { CardComment, fetchCommentById } from '../../services/comment.action';
import { getLessons } from '../../services/lesson.action';
import { getExerciceByChapter } from '../../services/quiz.action';
import { supabase } from '../../supabase';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Lesson = () => {

   let i =  -1  ;
   // let actualChapter = 0;
   // let nextChapter;
   const [doneBtn,setDoneBtn] = useState(false)
   const [loadQuizz,setLoadQuizz] = useState(false)
   const [newChapterQuizz,setNewChapterQuizz] = useState()
   const [loading, setLoading] = useState(true);
   const [questions,setQuestions] = useState([])
   const [enable,setEnable] = useState(false)
   const [showAnswer,setShowAnswer] = useState()
   const [evalExo,setEvalExo] = useState([])
   const [exerciceIndex,setExerciceIndex] = useState(0)
   const [actualLesson,setActualLesson] = useState(0)
   const [likeCourse,setLikedCourse] = useState(null)
   const [lessons,setLessons] = useState(null);
   const userId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id
   const [allComments,setAllComments] = useState([])
   const [comment,setComment] = useState('')

   let ChapterLessons;
   const [post,setPost] = useState(allComments)
   const [showQuizModal ,setShowQuizModal] = useState(false)
   const [isExploding, setIsExploding] = useState(false);
   const [isCourseCompleted, setIsCourseCompleted] = useState(false);

   const navigate = useNavigate();
   const HtmlContent = ({ html }) => (
      <>{parse(html)}</>
    );
   useEffect(() =>
   {
      const getAllComment = async() =>{
         let comments = await fetchCommentById()
         setAllComments(comments)
         sessionStorage.removeItem("openForm")
         console.log(comments)
      }
       
      const fetchLesson =  async () =>{
            ChapterLessons = await getLessons()  
            setLessons(ChapterLessons)
            if(sessionStorage.getItem("actualUserLesson")){
               setActualLesson(parseInt(sessionStorage.getItem("actualUserLesson")));
               sessionStorage.setItem("ActualLessonId",ChapterLessons[actualLesson].LessonID);
            }
            else
            for (const element of ChapterLessons) {
               i++
               setActualLesson(i); // Définir l'état avec la valeur actuelle de i
           
               if (element.Chapter_ID == sessionStorage.getItem("IdChapter")) {
                  console.log("LessonId",element.LessonID)
                  sessionStorage.setItem("ActualLessonId",element.LessonID)
                   break; // Sort complètement de la boucle
               }
           }
      }

      const subscribeToComment = () =>{
       const channel =  supabase.channel('custom-all-channel')
     .on(
       'postgres_changes',
       { event: '*', schema: 'public', table: 'User_Comment_Lesson' },
       (payload) => {
         console.log(allComments)
         setPost([payload.new, ...post])

       }
       
     )
     .subscribe()
     return () =>{
      supabase.removeChannel(channel)
     }
     }

     const requestExercice = async () => {
      if (newChapterQuizz !== null) {
          const exercices = await getExerciceByChapter(newChapterQuizz);
          setQuestions(exercices);
          setLoading(false);
          console.log(exercices);
      }
  };

     


      fetchLesson().then(() => {
         
         subscribeToComment()
         verifLikedCourse(); 
         getAllComment()
         requestExercice()
         console.log(newChapterQuizz)
         console.log(questions)
     });
     
   },[actualLesson,post,setPost,newChapterQuizz])

   useEffect(() => {
      if(questions.length === 0){
         setLoadQuizz(false);
      }
      else if (lessons) {
          const actualChapter = lessons[actualLesson].Chapter_ID;
          const nextChapter = lessons[actualLesson + 1]?.Chapter_ID || null;
          setLoadQuizz(actualChapter !== nextChapter);
          setNewChapterQuizz(actualChapter);  // Set the new chapter for the quiz
      }
  }, [lessons, actualLesson]);

  useEffect(() => {
      console.log(questions);  // This will log the updated questions
  }, [questions]);

  useEffect(() => {
   if (lessons && lessons.length && doneBtn !== null && doneBtn !== undefined) {
     checkCourseCompletion();
   }
 }, [actualLesson, doneBtn, lessons]);
 

 const checkCourseCompletion = () => {
   if (lessons && lessons.length && doneBtn !== null && doneBtn !== undefined) {
     if (actualLesson === lessons.length - 1 && doneBtn) {
       setIsCourseCompleted(true);
     }
   }
 };
 

 useEffect(() => {
   if (isCourseCompleted) {
     navigate('/auth/course/success')
   }
 }, [isCourseCompleted]);
  
   const nextLesson = () => {
      setLoadQuizz(false)
      sessionStorage.removeItem("openForm")
      if (actualLesson < lessons.length - 1) {
          setActualLesson(nextLesson => {
              const newLesson = nextLesson + 1;
              sessionStorage.setItem("actualUserLesson", newLesson);
              setLikedCourse(null); // Réinitialiser l'état de likeCourse
              verifLikedCourse(); // Mettre à jour likeCourse avec la nouvelle leçon
              return newLesson;
          });
      }
  };
  
  const prevLesson = () => {
   sessionStorage.removeItem("openForm")
      if (actualLesson > 0) {
          setActualLesson(prevLesson => {
              const newLesson = prevLesson - 1;
              sessionStorage.setItem("actualUserLesson", newLesson);
              setLikedCourse(null); // Réinitialiser l'état de likeCourse
              verifLikedCourse(); // Mettre à jour likeCourse avec la nouvelle leçon
              return newLesson;
          });
      }
  };
  

    const likedCourse = async(e) =>{
      e.preventDefault()
      const liked = await verifLikedCourse();
      try {
        if (liked === true ) {
          if (likeCourse == true) {
            await supabase
              .from('User_Liked_Lesson')
              .update({ "Liked": false })
              .eq('User_ID', userId)
              .eq("Lesson_ID",sessionStorage.getItem("ActualLessonId"))
              .select();
              setLikedCourse(false);
          } else {
            await supabase
              .from('User_Liked_Lesson')
              .update({ "Liked": true })
              .eq('User_ID', userId)
              .eq("Lesson_ID",sessionStorage.getItem("ActualLessonId"))
              .select();
              setLikedCourse(true);
          }
        }else{
          const { data, error } = await supabase
            .from('User_Liked_Lesson')
            .insert([
              {
                "User_ID": userId,
                "Lesson_ID": sessionStorage.getItem("ActualLessonId"),
                "Liked": true
              }
            ])
            .select();
          setLikedCourse(true);
        }
      } catch (error) {
        console.error(error); // Handle errors from both verifSavedCourse and update/insert
      }
    }

    const verifLikedCourse = async() =>{
         const {data,error} = await supabase
         .from("User_Liked_Lesson")
         .select("*")
         .eq("User_ID",userId)
         .eq("Lesson_ID",sessionStorage.getItem("ActualLessonId"))
         
         if(data.length > 0) {
            setLikedCourse(data[0].Liked)
              return true
          }
          else{
            setLikedCourse(false)
              return false
          }
  }

  const insertComment = async(e) =>{
   e.preventDefault()
   let IdLesson  = sessionStorage.getItem("ActualLessonId")
   const userImg = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.user_metadata.avatar_url
   
   
   const { data, error } = await supabase
   .from('User_Comment_Lesson')
   .insert([
   { CommentText: comment.toString(), Lesson_ID: IdLesson, User_ID: userId, imgComment: userImg},
   ])
   .select()
       
   setComment("")
}
// ///////////////////////////////////////////////
const handleCloseQuizModal = () => {
   setShowQuizModal(false);
 };


 const [currentSlide, setCurrentSlide] = useState(0);
 const [answers, setAnswers] = useState(Array(questions.length).fill(''));

useEffect(() =>{
   setAnswers(Array(questions.length).fill(''))
},[questions,setQuestions])

 const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current) => setCurrentSlide(current),
 };

 const handleNext = () => {
 setCurrentSlide(currentSlide + 1);
   setExerciceIndex(
      prevalue => {
         const newvalue = prevalue + 1;
         return newvalue
      }
   )
 };
 const handlePrevious = () => {
   
   setCurrentSlide(currentSlide - 1);
   setExerciceIndex(
      prevalue => {
         const newvalue = prevalue - 1;
         return newvalue
      }
   )
   
 };
 const handleAnswerChange = (index, answer, correctAnswer) => {
      const newTab = [...evalExo];
      newTab.splice(index, 1, answer === correctAnswer);
      setEvalExo(newTab);

   
    const newAnswers = [...answers];
    newAnswers[index] = answer ;
    setAnswers(newAnswers);
 };

   const handleSubmit = () => {
      const allFilled = answers.every(answer => answer !== "");
      if (!allFilled) {
      alert("Vous devez cocher toutes les cases!");
      return;
      }
      const isSuccess = evalExo.every(result => result );

         if (!isSuccess) {
               const currentTime = new Date().getTime();
               localStorage.setItem('quizFailureTime-'+newChapterQuizz.toString(), currentTime);
               alert("Vous avez échoué. Vous pouvez réessayer dans 24 heures.");
               setDoneBtn(true)
         }else{
            setDoneBtn(true)
            
         }
         setIsExploding(true)
      setEnable(true)
      console.log(answers);
      setShowAnswer(true)
   };

   const doneActionButton = () =>{
      setNewChapterQuizz(lessons[actualLesson + 1].Chapter_ID)
      nextLesson()
      setShowQuizModal(false)
      setEnable(false)
      setCurrentSlide(0)
      setExerciceIndex(0)
      setDoneBtn(false)
      setShowAnswer(false)
   }

 const checkQuizEligibility = () => {
   const failureTime = localStorage.getItem('quizFailureTime-'+newChapterQuizz.toString());
   if (failureTime) {
       const currentTime = new Date().getTime();
       const timeDifference = currentTime - failureTime;
       const hoursDifference = timeDifference / (1000 * 60 * 60);

       if (hoursDifference < 24) {
           alert("Vous devez attendre 24 heures avant de pouvoir repasser le quiz.");
           return false;
       } else {
           localStorage.removeItem('quizFailureTime');
       }
   }
   return true;
};

const startQuiz = () => {
   if (checkQuizEligibility()) {
       setShowQuizModal(true);
       if (isExploding) setIsExploding(false)
   }
};

 useEffect(() => {
   console.log("tab " + JSON.stringify(evalExo));
 }, [evalExo]);

 useEffect(() => {
   console.log("tab " +exerciceIndex);
 }, [exerciceIndex]);

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
 if (loading) {
   return <FadeLoader color="#2196F3" />;
}

   return (
      <>
         <section className="watch-video">
         <Suspense fallback={<FadeLoader color="#2196F3" />}>
         {lessons && (
            <div className="video-container">
               <div className="video">
                  <video src={lessons[actualLesson].URLVideo} controls poster="images/post-1-1.png" id="video"></video>
               </div>
               <h3 className="title">{lessons[actualLesson].Title}</h3>
               <div className="info">
                  <p className="date"><i className="fas fa-calendar"></i><span>{lessons[actualLesson].created_at.substring(0, 10)}</span></p>
                  <p className="date"><i className="fas fa-heart"></i><span>{lessons[actualLesson].TotalOfLiked ? lessons[actualLesson].TotalOfLiked : 0 } likes</span></p>
               </div>
               <div className="tutor">
                  <img src={sessionStorage.getItem("teacherImg")} alt="" />
                  <div>
                     <h3>{sessionStorage.getItem("teacherName")}</h3>
                     <span>{sessionStorage.getItem("specialization")}</span>
                  </div>
               </div>
               <form action="" className="flex">
                  <Link to={'/auth/teacher/profile'} className="inline-btn">view course</Link>
                  {
                  likeCourse ? (
                     <button onClick={likedCourse}><i className="fas fa-heart" style={{ color: '#2196F3' }}></i><span>like</span></button>
                  ) : likeCourse === false ? (
                     <button onClick={likedCourse}><i className="far fa-heart"></i><span>like</span></button>
                  ) : (
                    <button><span>like</span></button>
                  )
                }
               </form>
               <p className="description">
                  {
                     <div dangerouslySetInnerHTML={{__html: lessons[actualLesson].Content}} />    
                  }
               </p>
               <div className='controls'>
                  {actualLesson > 0 && (
                      <button className='next' onClick={prevLesson}>
                          <i className="fa-solid fa-chevron-left"></i>
                          <span>previous lesson</span>
                      </button>
                  )}
                  {(actualLesson >= lessons.length - 1 && questions.length !== 0) || loadQuizz ? (
                      <button className='next' onClick={startQuiz}>
                          <span>Quizz</span>
                          <i className="fa-solid fa-chevron-right"></i>
                      </button>
                  ) : actualLesson < lessons.length - 1 && (
                      <button className='next' onClick={nextLesson}>
                          <span>next lesson</span>
                          <i className="fa-solid fa-chevron-right"></i>
                      </button>
                  )}
               </div>
            </div>
        )}

        {!lessons &&  <FadeLoader
         color = "#2196F3"
        />}
         </Suspense>
         </section>

         <section className="comments">

            <h1 className="heading">{allComments.length} comments</h1>

            <form onSubmit={insertComment} className="add-comment">
               <h3>add comments</h3>
               <textarea name="comment_box" placeholder="enter your comment" value={comment} onChange={(e) => setComment(e.target.value)} required maxLength={1000} cols="30" rows="10"></textarea>
               <input type="submit" value="add comment" className="inline-btn" name="add_comment" />
            </form>

            <h1 className="heading">user comments</h1>

            <div className="box-container">
            {
              allComments && (
                <>
                  {
                    allComments.map(
                      Comment =>(
                        <>
                          <CardComment 
                          key ={Comment.id}
                          id ={Comment.id}
                          userName ={Comment.UserName}
                          imgUser ={Comment.imgComment}
                          comment= {Comment.CommentText}
                          date ={Comment.created_at}
                          idUser = {Comment.User_ID}
                          />
                        </>
                      )
                    )
                  }
                </>
              )
        }
            </div>

         </section>
            {questions.length > 0  && (
               <Modal show={showQuizModal} handleClose={handleCloseQuizModal}>
               <Slider {...settings} className='slider'>
                  {
                     <div key={exerciceIndex}>
                        <h2 style={{color: 'var(--black)'}}>{questions[exerciceIndex].QuestionText}</h2>
                        <div>
                        {questions[exerciceIndex].Options.map((option, optIndex) => (
                           <label key={optIndex} style={{color: 'var(--black)'}}>
                              <input
                              type="radio"
                              name={`question-${exerciceIndex}`}
                              value={option}
                              disabled={enable}
                              checked={answers[exerciceIndex] === option}
                              onChange={() => handleAnswerChange(exerciceIndex, option, questions[exerciceIndex].CorrectAnswer)}
                              />
                              {option}
                              {showAnswer && (
                         <>
                           {answers[exerciceIndex] === option ? (
                             option === questions[exerciceIndex].CorrectAnswer ? (
                               <i className="fa-solid fa-check" style={{ color: '#73ff00' }}></i>
                             ) : (
                               <i className="fa-solid fa-xmark" style={{ color: '#ff0000' }}></i>
                             )
                           ) : (
                              option === questions[exerciceIndex].CorrectAnswer ? (
                                 <i className="fa-solid fa-check" style={{ color: '#73ff00' }}></i>
                               ) : null
                           )}
                           
                         </>
                       )}
                       {isExploding && (<ConfettiExplosion /> )
                       }
                     </label>
                   ))}
                        </div>
                     </div>
                  }
               </Slider>
               <div className="navigation-buttons">
               <button onClick={handlePrevious} disabled={currentSlide === 0}>
                  Previous
               </button>
               <span>{currentSlide + 1} / {questions.length}</span>
               <button onClick={handleNext} disabled={currentSlide === questions.length - 1} style={{display: currentSlide === questions.length - 1 ? 'none' : ''}}>
                  Next
               </button>
               {currentSlide === questions.length - 1  && !doneBtn ?(
                  <button onClick={handleSubmit}>
                     Submit
                  </button>
               ): currentSlide === questions.length - 1  && doneBtn ?(
                  <button onClick={doneActionButton}>
                     Done
                  </button>
               ):(
                  <></>
               ) }
               </div>
            </Modal>
            )}
      </>
   );
}

export default Lesson;