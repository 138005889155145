import React, { useEffect, useState } from 'react';
import { getUserSession } from '../../../services/user.action';
import person_with_desk from '../../../assets/images/person_with_desk.png';
import { Link } from 'react-router-dom';

const SuccessRegistration = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    // Function to fetch user session data
    const fetchUserSession = async () => {
      const userData = await getUserSession();
      setUser(userData);
    };

    // Call fetchUserSession when component mounts
    fetchUserSession();
    localStorage.setItem('userRole', "teacher")
  }, []); // Dependency array is empty to ensure it runs only once on mount

  return (
    <div className="success-registration">
      <img
        src={person_with_desk}
        alt="Person at desk"
        className="success-image"
        style={{ width: 300, height: 300 }}
      />
      <div className="success-message">
        <h1>Congratulations <span style={{color: "var(--main-color)"}}>“{user['username']}”</span> You Are About to Make a Great Decision!</h1>
        <p>
          You have skills to share in one or more areas; so join our community of over 1500 teachers on ITESTUDY.
        </p>
        <p>
          Share your knowledge and skills with our users and get paid monthly.
        </p>
        <Link to={'/auth/teacher/dashboard'} className="join-button">Go to dashboard</Link>
      </div>
    </div>
  );
};

export default SuccessRegistration;
