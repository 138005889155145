import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { CardTeacher,allTeachers } from '../../services/teacher.action';

const Teachers = () => {

  const [teachers,setTeachers] = useState()
  const [search,setSearch] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() =>{
    const getTeachers = async() =>{
      let letTeachers = await allTeachers()
      setTeachers(letTeachers)
    }

    getTeachers()
  },[])

  return (
    <>
      <section className="teachers">

        <h1 className="heading">expert teachers</h1>

        <form action="" className="search-tutor" onSubmit={handleSubmit}>
          <input type="text" name="search_box" placeholder="search tutors..." required maxlength={100} onChange={(e) => setSearch(e.target.value)}/>
          <button type="submit" className="fas fa-search" name="search_tutor" ></button>
        </form>

        <div className="box-container">

          <div className="box offer">
            <h3>become a tutor</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, itaque ipsam fuga ex et aliquam.</p>
            <Link href="register" className="inline-btn" onClick={() => { alert(' You are not eligible') }}>get started</Link>
          </div>

          {
              teachers && (
                <>
                  {
                    teachers
                    .filter((teacher) => {
                      return search.toLowerCase() === '' ? teacher
                      : JSON.stringify(teacher).toLowerCase().includes(search.toLowerCase());
                    })
                    .map(
                      teacher =>(
                          <CardTeacher
                          teacherName = {teacher.item.Name}
                          teacherThumbnail = {teacher.item.Thumbnail}
                          teacherSpecialization = {teacher.item.Specialization}
                          playlistLength = {teacher.teacherCours.length}
                          teacherVideosLength = {teacher.teacherVideos.length}
                          teacherLikesLength = {teacher.likeCourslength.length}
                          />
                      )
                    )
                  }
                </>
              )
        }

        </div>

      </section>
    </>
  );
}

export default Teachers;
