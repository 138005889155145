import React from "react"
import { supabase } from "../supabase"

export const getSubjectCourse = async() =>{
    const { data: Subject, error } = await supabase
    .from('Subject')
    .select('SubjectName')   
    
    if(error){
        console.error(error)
    }else{
        return Subject
    }
}

export const updateCourseWithoutImage = async(Course_Id,title,description,prix) =>{
 
  if(title.length != 0){
    if(description.length != 0){
      if(prix.toString().length != 0){

        const { data, error } = await supabase
        .from('Course')
        .update(
        {
          Title: title,
          Description: description,
          Price: prix
        }
        )
        .eq('CourseID', Course_Id)
        .select()
        
        if(error){
            return false;
        }else{
            localStorage.setItem('teacherManageCourseId',data[0].CourseID)
            return true
        }
      }
      return false
    }    
    return false
  }  
  return false   
}

export const updateCourse = async(userId,Course_Id, image,title,description,prix) =>{
 
  if(title.length != 0){
    if(description.length != 0){
      if(prix.toString().length != 0){

        const { data, error } = await supabase
        .from('Course')
        .update([
          { Title: title },
          {Description: description},
          {Price: prix}
        ])
        .eq('CourseID', Course_Id)
        .select()
        
        if(error){
            return false;
        }else{
            localStorage.setItem('teacherManageCourseId',data[0].CourseID)
            let uploadImgVal = await handleUpload(userId,data[0].CourseID, image)
    
            if(uploadImgVal)
            return true;
            else
            return false
        }
      }
      return false
    }    
    return false
  }  
  return false   
}

export const createCourse = async(userId, image) =>{
 
  if(localStorage.getItem('CourseTeacherTitle').length != 0){
    if(localStorage.getItem('CourseTeacherDescription').length != 0){
      if(localStorage.getItem('CourseTeacherPrice').length != 0){
        const { data, error } = await supabase
        .from('Course')
        .insert([
        { 
            Title: localStorage.getItem('CourseTeacherTitle'),
            Description: localStorage.getItem("CourseTeacherDescription").toString(),
            Type: localStorage.getItem("CourseTeacherPrice") > 0 ? "Paid" : "Free",
            Price: Number(localStorage.getItem("CourseTeacherPrice")),
            duration: 10,
            Teacher_ID: userId.toString()
        },
        ])
        .select()
    
        if(error){
            return false;
        }else{
            localStorage.setItem('teacherManageCourseId',data[0].CourseID)
            let uploadImgVal = await handleUpload(userId,data[0].CourseID, image)
    
            if(uploadImgVal)
            return true;
            else
            return false
        }
      }
      return false
    }    
    return false
  }  
  return false   
}

export const createChapterForCourse = async(title,description) =>{
 let courseId = localStorage.getItem("teacherManageCourseId")
 
  if(title && description){
    if(!courseId){
      alert("You have not selected or created a course")
    }else{
      const { data, error } = await supabase
      .from('Chapter')
      .insert([
      { 
          Title: title,
          Description: description,
          Course_ID: courseId
      },
      ])
      .select()
    
      if(error){
          return false;
      }else{
          return true;
      }
    }  
  }   
}

export const handleUpload = async (userId,CourseID, image) => {
    if (!image) return;
  
    const response = await fetch(image);
    const blob = await response.blob();
    const filePath = `${userId}/Course/${CourseID}/${new Date().getTime()}.${blob.type.split('/')[1]}`;
  
    const { data, error } = await supabase.storage
      .from('TeacherImageCourse')
      .upload(filePath, blob);
      
    if (error) {
      console.error('Error uploading image:', error);
      return false
    } else {
      const publicUrl = supabase.storage
        .from('TeacherImageCourse')
        .getPublicUrl(filePath).data.publicUrl;
  
        const { data, error: dbError } = await supabase
        .from('Course')
        .update({ Thumbnail: publicUrl })
        .eq('CourseID', CourseID)
        .select()
        
  
      if (dbError) {
        console.error('Error inserting image into database:', dbError);
        return false
      } else {
        console.log('Image uploaded and saved successfully:', publicUrl);
        return true
      }
    }
  };