import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import contact_img from "../../../assets/images/contact-img.svg"
import sendMail from '../../../services/email.bakend';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

const Contact = () => {
  // name, email, number, message
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     emailjs.sendForm(
      "service_81eu0fv",
        "template_ddupf8r",
        form.current,
        {
          publicKey: "31wfPCNpzsQp4fDsI",
        })
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log('FAILED...', error.text);
          },  
        )
        e.target.reset();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      <section className="contact">

        <div className="row">
          <div className="image">
            <img src={contact_img} alt=""/>
          </div>

          <form ref={form} onSubmit={handleSubmit}>
            <h3>get in touch</h3>
            <input type="text" placeholder="enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required maxLength={50} className="box"/>
            <input type="email" placeholder="enter your email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required maxLength={50} className="box"/>
            <input type="text" placeholder="enter your subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required maxLength={50} className="box"/>
            <textarea name="msg" className="box" placeholder="enter your message" value={message} onChange={(e) => setMessage(e.target.value)} required maxLength={1000} cols="30" rows="10"></textarea>
            <input type="submit" value="Send" className="inline-btn" name="submit"/>
          </form>
        </div>

          <div className="box-container">

              <div className="box">
                <i className="fas fa-phone"></i>
                <h3>phone number</h3>
                <Link href="tel:1234567890">+237 689489890</Link>
                <Link href="tel:1112223333">+237 637827823</Link>
              </div>
              
              <div className="box">
                <i className="fas fa-envelope"></i>
                <h3>email address</h3>
                <Link href="mailto:lenked@gmail.com">lenked@gmail.come</Link>
                <Link href="mailto:samy@gmail.com">samy@gmail.come</Link>
              </div>

              <div className="box">
                <i className="fas fa-map-marker-alt"></i>
                <h3>office address</h3>
                <Link href="#">Douala-Cameroun, Logbessou-IUC, Akwa-IUC</Link>
              </div>

          </div>

        </section>
    </>
  );
}

export default Contact;


/**import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import contact_img from "../../../assets/images/contact-img.svg"
import sendMail from '../../../services/email.bakend';
import emailjs from 'emailjs-com'

const Contact = () => {
  // name, email, number, message
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [specialization, setSpecialization] = useState(sessionStorage.getItem("selectedLabel"))
  const [yearsExperience, setyearsExperience] = useState(sessionStorage.getItem("YearsExperience"))

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await emailjs.send(
        'YOUR_SERVICE_ID',
        'YOUR_TEMPLATE_ID',
        {
          name: name,
          email: email,
          subject: subject,
          message: message,
        },
        'YOUR_USER_ID'
      );
      console.log(result.text);
      setResponseMessage('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      setResponseMessage('Failed to send email.');
    }
  };

  return (
    <>
      <section className="contact">

        <div className="row">
          <div className="image">
            <img src={contact_img} alt=""/>
          </div>

          <form onSubmit={handleSubmit}>
            <h3>get in touch</h3>
            <input type="text" placeholder="enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required maxLength={50} className="box"/>
            <input type="email" placeholder="enter your email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required maxLength={50} className="box"/>
            <input type="hidden" name="specialization" value={specialization} className="box" />
            <input type="hidden" name="yearsExperience" value={yearsExperience} className="box" />
            <input type="text" placeholder="enter your subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required maxLength={50} className="box"/>
            <textarea name="msg" className="box" placeholder="enter your message" value={message} onChange={(e) => setMessage(e.target.value)} required maxLength={1000} cols="30" rows="10"></textarea>
            <input type="submit" value="send message" className="inline-btn" name="submit"/>
          </form>
        </div>

          <div className="box-container">

              <div className="box">
                <i className="fas fa-phone"></i>
                <h3>phone number</h3>
                <Link href="tel:1234567890">+237 689489890</Link>
                <Link href="tel:1112223333">+237 637827823</Link>
              </div>
              
              <div className="box">
                <i className="fas fa-envelope"></i>
                <h3>email address</h3>
                <Link href="mailto:lenked@gmail.com">lenked@gmail.come</Link>
                <Link href="mailto:samy@gmail.com">samy@gmail.come</Link>
              </div>

              <div className="box">
                <i className="fas fa-map-marker-alt"></i>
                <h3>office address</h3>
                <Link href="#">Douala-Cameroun, Logbessou-IUC, Akwa-IUC</Link>
              </div>

          </div>

        </section>
    </>
  );
}

export default Contact;
 */