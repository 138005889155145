import { createClient } from '@supabase/supabase-js';

export const supabase_config = {
  supabase_url: process.env.REACT_APP_SUPABASE_URL,
  supabase_key: process.env.REACT_APP_SUPABASE_ANON_KEY
}

export const supabase = createClient(
  supabase_config.supabase_url,
  supabase_config.supabase_key
);