import React from "react";
import { Link } from 'react-router-dom';
import { supabase } from "../supabase";

export const getTeacherChapterByCoursId = async() =>{
  const coursId = localStorage.getItem("teacherManageCourseId")

  const {data,error} = await supabase
  .from("Chapter")
  .select('*')
  .eq('Course_ID',coursId)

  if(error){
    console.error(error)
    return []
  }else{
    data.sort((a,b) => new Date(a.created_at) - new Date(b.created_at));
    return data
  }
}


export const getTeacherLessonByChapterId = async(idChapter) =>{
  const {data,error} = await supabase
  .from('Lesson')
  .select('LessonID,Title')
  .eq('Chapter_ID',idChapter)

  if(error){
    console.error(error)
    return []
  }else{
    console.log(data)
    return data
  }
}

export const getTeacherQuestionsByChapterId = async(idChapter) =>{
  const {data,error} = await supabase
  .from('EvaluationQuestion')
  .select('*')
  .eq('Chapter_ID',idChapter)

  if(error){
    console.error(error)
    return []
  }else{
    console.log(data)
    return data
  }
}

export const updateChapterForCourse = async(id,title,description) =>{
  
   if(title && description){
      const { data, error } = await supabase
      .from('Chapter')
      .update(
        {Title: title,Description: description}
      )
      .eq('ChapterID', id)
      .select()
              
     
       if(error){
           return false;
       }else{
           return true;
       }
     }    
 }

export const removeChapterById = async(id) =>{

  const { error } = await supabase
  .from('Chapter')
  .delete()
  .eq('ChapterID', id)

  if(error)
    return false
  else
    return true
}

export const CardChapter = (props) =>{

    function getIdChapter(){
      sessionStorage.setItem("IdChapter",props.id)
      sessionStorage.removeItem("actualUserLesson")
    }

    return(
        <Link className="box" to={'/auth/course/details/chapter/details/lesson'} onClick={() => getIdChapter()} key={props.id}>
        <i className="fas fa-play"></i>
        <img src={props.image} alt=""/>
        <h3>{props.title}</h3>
      </Link>
    )
}