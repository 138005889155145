import React, { useEffect } from 'react';
import pic_1 from "../../../assets/images/pic-1.jpg"
import pic_2 from "../../../assets/images/pic-2.jpg"
import pic_3 from "../../../assets/images/pic-3.jpg"
import pic_4 from "../../../assets/images/pic-4.jpg"
import pic_5 from "../../../assets/images/pic-5.jpg"
import pic_6 from "../../../assets/images/pic-6.jpg"
import pic_7 from "../../../assets/images/pic-7.jpg"
import about_img from "../../../assets/images/about-img.svg"
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn');
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    toggleBtn.addEventListener('click', () => {
      darkMode = localStorage.getItem('dark-mode');
      if (darkMode === 'disabled') {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    let profile = document.querySelector('.header .flex .profile');

    document.querySelector('#user-btn').addEventListener('click', () => {
      profile.classList.toggle('active');
      // search.classList.remove('active');
    });

    let sideBar = document.querySelector('.side-bar');

    document.querySelector('#menu-btn').addEventListener('click', () => {
      sideBar.classList.toggle('active');
      body.classList.toggle('active');
    });

    document.querySelector('#close-btn').addEventListener('click', () => {
      sideBar.classList.remove('active');
      body.classList.remove('active');
    });

    window.addEventListener('scroll', () => {
      profile.classList.remove('active');

      if (window.innerWidth < 1200) {
        sideBar.classList.remove('active');
        body.classList.remove('active');
      }
    });
  }, []);
  return (
    <div className='welcome'>
      <header className="header">
      
      <section className="flex">

          <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>
          
          <nav className="navbar">
            <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
            <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
            <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
          </nav>

          <div className="icons">
            <div id="menu-btn" className="fas fa-bars"></div>
            <div id="user-btn" className="fas fa-user"></div>
            <div id="toggle-btn" className="fas fa-sun"></div>
          </div>

          <div className="profile">
            <img src={pic_1} className="image" alt="" />
            <h3 className="name">No user</h3>
            <p className="role">✖️</p>
            <Link to={'/login'} className="btn">Login</Link>
            <div className="flex-btn">
                <Link to={'/register'} className="option-btn">Register</Link>
            </div>
          </div>

      </section>

    </header>
      <div className="side-bar">

        <div id="close-btn">
            <i className="fas fa-times"></i>
        </div>

        <div className="profile">
            <img src={pic_1} className="image" alt="" />
            <h3 className="name">No user</h3>
            <p className="role">✖️</p>
            <Link to={'/login'} className="btn">Login</Link>
            <div className="flex-btn">
                <Link to={'/register'} className="option-btn">Register</Link>
            </div>
          </div>

        <nav className="navbar">
            <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
            <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
            <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
          </nav>

        </div>
      <section className="about">

        <div className="row">

            <div className="image">
              <img src={about_img} alt="" />
            </div>

            <div className="content">
              <h3>why choose us?</h3>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut dolorum quasi illo? Distinctio expedita commodi, nemo a quam error repellendus sint, fugiat quis numquam eum eveniet sequi aspernatur quaerat tenetur.</p>
              <Link to={"/login"} className="inline-btn">our courses</Link>
            </div>

        </div>

        <div className="box-container">

            <div className="box">
              <i className="fas fa-graduation-cap"></i>
              <div>
                  <h3>+10k</h3>
                  <p>online courses</p>
              </div>
            </div>

            <div className="box">
              <i className="fas fa-user-graduate"></i>
              <div>
                  <h3>+40k</h3>
                  <p>brilliant students</p>
              </div>
            </div>

            <div className="box">
              <i className="fas fa-chalkboard-user"></i>
              <div>
                  <h3>+2k</h3>
                  <p>expert tutors</p>
              </div>
            </div>

            <div className="box">
              <i className="fas fa-briefcase"></i>
              <div>
                  <h3>100%</h3>
                  <p>job placement</p>
              </div>
            </div>

        </div>

      </section> 

      <section className="reviews">

        <h1 className="heading">student's reviews</h1>

        <div className="box-container">

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_2} alt="" />
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_3} alt="" />
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_4} alt=""/>
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_5} alt=""/>
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_6} alt=""/>
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

            <div className="box">
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              <div className="student">
                  <img src={pic_7} alt=""/>
                  <div>
                    <h3>john deo</h3>
                    <div className="stars">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star-half-alt"></i>
                    </div>
                  </div>
              </div>
            </div>

        </div>

      </section>
      <footer className="footer">

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

      </footer>
    </div>
  );
}

export default About;
