import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { createCourse } from '../../../services/addCourse.action';
import { getSubjectCourse } from '../../../services/addCourse.action';
import { ClipLoader } from 'react-spinners';

const StepOne = ({ nextStep }) => {
  const userId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id;
  const [ Categories,setCategories ] = useState([])
  const [isLoading,setIsLoading] = useState(false);
  const [ title,setTitle ] = useState()
  const [ prix,setPrix ] = useState('')
  const [ description,setDescription ] = useState('')
  const [ selectCategorie,setSelectCategorie ] = useState([])
  const [ image, setImage] = useState();


  
  useEffect(() =>{
    const storedImage = JSON.parse(sessionStorage.getItem('backImage'));
    setImage(storedImage);

    const retrieveCategorie = async() =>{
      const categorie = await getSubjectCourse()
      console.log(categorie)
      setCategories(categorie)
    }

    retrieveCategorie()
  },[])

  useEffect(() =>{
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('backImage');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  })

  const createTeacherCourse = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    const result = await createCourse(userId,image)
    if(image){
      if(result){
        alert("Cours was create with success")
        nextStep()
        localStorage.setItem('CourseTeacherTitle',"")
        localStorage.setItem("CourseTeacherDescription","")
        localStorage.setItem("CourseTeacherPrice","")
        localStorage.setItem("CourseTeacherCategorie","")
        setIsLoading(false)

      }else{
        setIsLoading(false)
      }
    }else{
      alert("Please select an image")
    }
  }

  const handleSelectChange = (e) => {
    setSelectCategorie(e.target.value);
    localStorage.setItem("CourseTeacherCategorie",e.target.value)
  };

  const removeImage = () => {
    setImage("");
    sessionStorage.removeItem('backImage');
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file && file.type.startsWith('image/')) {
        const newImage = [URL.createObjectURL(file)];
        setImage(newImage);
        sessionStorage.setItem('backImage', JSON.stringify(newImage));
    }
  };

  const navigate = useNavigate()
  return (
    !isLoading?(
      <form>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 10}}>
        <h1>Courses / <span style={{ color: '#007bff' }}>Add Courses</span></h1>
        <h1>Etape <span style={{ color: '#007bff' }}>1</span>/5</h1>
      </div>
      <p style={{fontSize: "1.4rem", marginBottom: 10}}>Veuillez ajouter les informations de base concernant votre cours.</p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '60%' }}>
          <div style={{ marginBottom: '20px' }}>
            <label style={{fontSize: 16}}>Titre du cours :</label>
            <input type="text" value={localStorage.getItem('CourseTeacherTitle')} onChange={(e) =>{
              setTitle(e.target.value)
              localStorage.setItem('CourseTeacherTitle',e.target.value)
            }} required maxLength={30} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label style={{fontSize: 16}}>Prix du cours (en XAF) :</label>
            <input type="number" value={localStorage.getItem('CourseTeacherPrice')} onChange={(e) => {
              if(/^\d{0,5}$/.test(e.target.value)){
                setPrix(e.target.value)
                localStorage.setItem("CourseTeacherPrice",e.target.value)
              }
              }} required maxLength={5} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label style={{fontSize: 16}}>Quel est la categorie de votre cours :</label>
            <select style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }}
              value={selectCategorie} 
              onChange={handleSelectChange}>
              <option>categories</option>

              {
                Categories.map(
                    categorie =>(
                      <option value={categorie.SubjectName}>{categorie.SubjectName}</option>
                  )
                )
              }
            </select>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label style={{fontSize: 16}}>Description :</label>
            <textarea value={localStorage.getItem('CourseTeacherDescription')} onChange={(e) => 
            {setDescription(e.target.value)
              localStorage.setItem("CourseTeacherDescription",e.target.value)
            }} required maxLength={1000} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none' }} rows="4"></textarea>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label style={{fontSize: 16}}>Photo de couverture :</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="cover-photo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', height: '100px', border: '1px dashed #ccc', cursor: 'pointer', borderRadius: '5px' }}>
                {
                  image ?(
                    <span style={{fontSize: 16}}>Modifier</span>
                  ):(
                    <span style={{fontSize: 16}}>Ajouter</span>
                  )
                }
              </label>
              <input type="file" id="cover-photo" required accept="image/*" onChange={handleImage} style={{ display: 'none' }} />
            </div>
          </div>
          {
            image ? (
              <div style={{ position: 'relative', margin: '5px' }}>
                <img className='imgStepTwo'
                 onClick={() => removeImage()}
                 style={{
                   position: 'absolute',
                   top: '5px',
                   right: '5px',
                   background: 'rgba(252, 3, 69, 0.8)',
                   border: 'none',
                   borderRadius: '50%',
                   cursor: 'pointer',
                   display: 'none',
                   justifyContent: 'center',
                   alignItems: 'center',
                   fontSize: '20px'
                 }}
                  src={image}
                  alt={`image`}
                  style={{ width: "150px", height: "100px", objectFit: 'cover' }}
                />
              </div>
            ):(
              <></>
            )
          }

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={() => {navigate(-1)}} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Cancel</button>
            <button onClick={createTeacherCourse} type='submit' style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Create The Course</button>
            <button onClick={nextStep} style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
          </div>
        </div>
        <div style={{ width: '35%', padding: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
          <h2 style={{fontSize: 16}}>Preview</h2>
          <div style={{ width: '100%', height: '200px', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>Preview</div>
        </div>
      </div>
    </form>
    ):(
        <ClipLoader
          color={'#2196F3'}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
    )
  );
};

export default StepOne;
