import React from 'react';
import { useState,useEffect } from 'react';
import { CircleLoader } from 'react-spinners';
import { CardCourse, getAllCourse } from '../../services/course.action';
import no_data_found_img from "../../assets/images/no_data_found.png"

const Search = () => {
  const [search,setSearch] = useState('')
  const [allCourse,setAllCourse] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchBoxValue = urlParams.get('search_box');
    setSearch(searchBoxValue)
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() =>{
    async function fetchData() {
    try{
        setIsLoading(true)
        const cours = await getAllCourse()
        if(cours){
          setIsLoading(false)
          setAllCourse(cours)

          
        }
        else{
          setIsLoading(false)
          console.log(cours.Course)
        }
      
    }
    catch(error){
        console.error(error)
    }
    
  }
  fetchData()
},[]);

  return (
    <section className="search">
      <h3>Search Results for: "{search}"</h3>
      <form className="search-form" onSubmit={handleSubmit}>
        <input type="text" name="search_box" required placeholder="search courses..." maxLength={100} onChange={(e) => setSearch(e.target.value)} />
        <button type="submit" className="fas fa-search"></button>
      </form>
      {!isLoading ? (
      <div className="box-container">
     {
          (!search || !allCourse) ? (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <img src={no_data_found_img} alt="No data found" />
              <p style={{fontSize: 20, color: 'var(--black)', fontWeight: 'bold'}}>Nothing courses finding!</p>
            </div>
          ) : (
            allCourse
            .filter((cours) => {
              return search.toLowerCase() === '' ? cours
              : JSON.stringify(cours).toLowerCase().includes(search.toLowerCase());
            })
            .map(
              cours => (
                <CardCourse 
                  key={cours.cour.CourseID}
                  id={cours.cour.CourseID}
                  teacherName={cours.Name}
                  teacherImg={cours.Thumbnail}
                  chapterNumber={cours.lessonCount}
                  teacherSpecialization={cours.Specialization}
                  notation={cours.courseRating}
                  date={cours.cour.created_at}
                  image={cours.cour.Thumbnail}
                  title={cours.cour.Title}
                />
              )
            )
          )
        }

      </div>
                      ): (
                        <CircleLoader
                          color={'#2196F3'}
                          loading={isLoading}
                          size={150}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                        )}
    </section>
  );
}

export default Search;
