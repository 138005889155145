import { URL_APP } from "..";
import { supabase } from "../../supabase";
import Cookies from 'js-cookie';



export const signInWithGoogle = async () => {
    try {
      const { data, error, user, session } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo : URL_APP + "auth/home",
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });
      
      if (error) {
        console.error("Erreur lors de la connexion avec Google:", error.message);
        return { success: false, error: error.message };
      } else {
        console.log("Connexion avec Google réussie:", data.url);
        localStorage.setItem("isAuth", JSON.stringify(true));
        const userSession = {
          UserName: user.email,
          Email: user.email,
        };
        sessionStorage.setItem("userSession", JSON.stringify(userSession));
        Cookies.set("supabase_session", JSON.stringify({ email: user.email }), {
          secure: true,
          sameSite: "Strict",
        });
        return { success: true, user, session };
      }
    } catch (error) {
      console.error("Erreur inattendue lors de la connexion avec Google:", error.message);
      return { success: false, error: "Une erreur inattendue s'est produite." };
    }
  };