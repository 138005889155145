import React from 'react';
import StepVerification from './StepVerification';

const Verification = () => {
  return (
    <>
      <StepVerification />
      <br /><br /><br /><br /><br /><br /><br />
    </>
  );
}

export default Verification;
