import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import success_img from "../../assets/images/success_img.svg.png";

const SuccessRegister = () => {
  useEffect(() => {
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');
    const enableDarkMode = () => {
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }
    if (darkMode === 'enabled') {
      enableDarkMode();
    }
  });
  return (
    <>
      <header className="header" style={{ pointerEvents: 'none' }}>

        <section className="flex">

          <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>

        </section>

      </header>
      <section className="form-container">
        <form action="" encType="multipart/form-data">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={success_img} alt="" />
          </div>
          <h3>Confirmation Email Sent</h3>
          <p style={{ textAlign: 'center' }}>Check your email to confirm your account and close this page.</p>
        </form>
      </section>
    </>
  );
}

export default SuccessRegister;
