import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import contact_img from "../../../assets/images/contact-img.svg";
import pic_1 from "../../../assets/images/pic-1.jpg";

const Contact = () => {
  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn');
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    toggleBtn.addEventListener('click', () => {
      darkMode = localStorage.getItem('dark-mode');
      if (darkMode === 'disabled') {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    let profile = document.querySelector('.header .flex .profile');

    document.querySelector('#user-btn').addEventListener('click', () => {
      profile.classList.toggle('active');
      // search.classList.remove('active');
    });

    let sideBar = document.querySelector('.side-bar');

    document.querySelector('#menu-btn').addEventListener('click', () => {
      sideBar.classList.toggle('active');
      body.classList.toggle('active');
    });

    document.querySelector('#close-btn').addEventListener('click', () => {
      sideBar.classList.remove('active');
      body.classList.remove('active');
    });

    window.addEventListener('scroll', () => {
      profile.classList.remove('active');

      if (window.innerWidth < 1200) {
        sideBar.classList.remove('active');
        body.classList.remove('active');
      }
    });
  }, []);
  // Hook
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async () => {
    
  };

  return (
    <div className='welcome'>
      <header className="header">

        <section className="flex">

          <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>

          <nav className="navbar">
            <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
            <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
            <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
          </nav>

          <div className="icons">
            <div id="menu-btn" className="fas fa-bars"></div>
            <div id="user-btn" className="fas fa-user"></div>
            <div id="toggle-btn" className="fas fa-sun"></div>
          </div>

          <div className="profile">
            <img src={pic_1} className="image" alt="" />
            <h3 className="name">No user</h3>
            <p className="role">✖️</p>
            <Link to={'/login'} className="btn">Login</Link>
            <div className="flex-btn">
              <Link to={'/register'} className="option-btn">Register</Link>
            </div>
          </div>

        </section>

      </header>
      <div className="side-bar">

        <div id="close-btn">
          <i className="fas fa-times"></i>
        </div>

        <div className="profile">
          <img src={pic_1} className="image" alt="" />
          <h3 className="name">No user</h3>
          <p className="role">✖️</p>
          <Link to={'/login'} className="btn">Login</Link>
          <div className="flex-btn">
            <Link to={'/register'} className="option-btn">Register</Link>
          </div>
        </div>

        <nav className="navbar">
          <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
          <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
          <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
        </nav>

      </div>
      <section className="contact">

        <div className="row">
          <div className="image">
            <img src={contact_img} alt="" />
          </div>

          <form onSubmit={handleSubmit}>
            <h3>get in touch</h3>
            <input type="text" placeholder="enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required maxLength={50} className="box"/>
            <input type="email" placeholder="enter your email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required maxLength={50} className="box"/>
            <input type="text" placeholder="enter your subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required maxLength={50} className="box"/>
            <textarea name="msg" className="box" placeholder="enter your message" value={message} onChange={(e) => setMessage(e.target.value)} required maxLength={1000} cols="30" rows="10"></textarea>
            <input type="submit" value="send message" className="inline-btn" name="submit"/>
          </form>
        </div>

        <div className="box-container">

          <div className="box">
            <i className="fas fa-phone"></i>
            <h3>phone number</h3>
            <Link href="tel:1234567890">+237 689489890</Link>
            <Link href="tel:1112223333">+237 637827823</Link>
          </div>

          <div className="box">
            <i className="fas fa-envelope"></i>
            <h3>email address</h3>
            <Link href="mailto:lenked@gmail.com">lenked@gmail.come</Link>
            <Link href="mailto:samy@gmail.com">samy@gmail.come</Link>
          </div>

          <div className="box">
            <i className="fas fa-map-marker-alt"></i>
            <h3>office address</h3>
            <Link href="#">Douala-Cameroun, Logbessou-IUC, Akwa-IUC</Link>
          </div>

        </div>

      </section>
      <footer className="footer">

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

      </footer>
    </div>
  );
}

export default Contact;
