import { supabase } from "../supabase";

export const getLessons = async() =>{
    const coursId = sessionStorage.getItem("IdCourse");
    try{
        const {data} = await supabase
        .from("Chapter")
        .select("ChapterID")
        .eq("Course_ID",coursId);

            if(data){
                const updateData = await Promise.all(data.map(async(chapterId) =>{
                    let idChap = chapterId.ChapterID
                    const lessons = await getLessonsFormChapters(chapterId.ChapterID);
                    const updateData2 = await Promise.all(lessons.map(async(lesson) =>{
                        const URLVideo = await getTutorial(lesson.LessonID);
                        return {...lesson,URLVideo,idChap}
                    }))
                    return updateData2
                }));
                const resolvedData = await Promise.all(updateData);
                    console.log(resolvedData);
                    return resolvedData.flat()
            }     
    }
    catch(error){
        console.log(error)
    }
    
} 


export const getLessonsFormChapters = async(id) =>{
    try{
        const { data } = await supabase
        .from("Lesson")
        .select("*")
        .eq('Chapter_ID', id)

    return data
    }
    catch(error){
        console.error(error)
    }
}

const getTutorial = async(idLesson) =>{
    try{
        const {data} = await supabase
        .from("Tutorial")
        .select("VideoUrl")
        .eq("Lesson",idLesson)
        .single()

        return data.VideoUrl
    }
    catch(error){
        console.error()
    }
}

export const handleUploadVideo = async (teacherId, LessonID, videoFile, videoLink) => {
  if(videoFile){
    console.log("Video file received:", videoFile); // Vérifiez le fichier reçu
    if (!videoFile || !(videoFile instanceof File)) {
      console.error('Invalid video file');
      return false;
    }
    console.log("Reading video file...");
  
    try {
      const reader = new FileReader();
  
      const blob = await new Promise((resolve, reject) => {
        reader.onloadend = () => {
          console.log("File read successfully"); // Ajouté pour vérifier la lecture
          resolve(new Blob([reader.result], { type: videoFile.type }));
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(videoFile);
      });
  
      console.log("Blob created:", blob); // Vérifiez le Blob
  
      if (!blob.type) {
        console.error('Video file type is not defined');
        return false;
      }
  
      const fileExtension = blob.type.split('/')[1];
      const filePath = `${teacherId}/Course/${LessonID}/${new Date().getTime()}.${fileExtension}`;
  
      const { data, error: uploadError } = await supabase.storage
        .from('LessonVideoCourse')
        .upload(filePath, blob);
  
      if (uploadError) {
        console.error('Error uploading video:', uploadError);
        return false;
      } else {
        const publicUrl = supabase.storage
          .from('LessonVideoCourse')
          .getPublicUrl(filePath).data.publicUrl;
  
        const { data: dbData, error: dbError } = await supabase
          .from('Tutorial')
          .insert([{
            VideoUrl: publicUrl,
            Lesson: LessonID,
            Teacher_ID: teacherId
          }])
          .select();
  
        if (dbError) {
          console.error('Error inserting video into database:', dbError);
          return false;
        } else {
          console.log('Video uploaded and saved successfully:', publicUrl);
          return true;
        }
      }
    } catch (error) {
      console.error('Error during video upload process:', error);
      return false;
    }
  }else if(videoLink){
     try { 
        const { data: dbData, error: dbError } = await supabase
        .from('Tutorial')
        .insert([{
          VideoUrl: videoLink,
          Lesson: LessonID,
          Teacher_ID: teacherId
        }])
        .select();

      if (dbError) {
        console.error('Error inserting video into database:', dbError);
        return false;
      } else {
        console.log('Video uploaded and saved successfully:', videoLink);
        return true;
      }
    } catch (error) {
    console.error('Error during video upload process:', error);
    return false;
    }
  }
  };
  

  export const createLesson = async (idChapter, title, content, videoFile, videoLink) => {
    const teacherId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id;
    const { data, error } = await supabase
      .from('Lesson')
      .insert([
        {
          Title: title.toString(),
          Content: content,
          Chapter_ID: idChapter
        }
      ])
      .select();
  
    if (error) {
      console.error('Error inserting lesson:', error);
      return false;
    } else {
      const lessonId = data[0].LessonID;
      sessionStorage.setItem('lessonVideoId', lessonId);
      const uploadVideoVal = await handleUploadVideo(teacherId, lessonId, videoFile, videoLink);
  
      return uploadVideoVal;
    }
  };

  export const getLessonById = async(id) =>{
    const {data,error} = await supabase
    .from('Lesson')
    .select('Title,Content')
    .eq('LessonID',id)
    .single()

    if(data)
      return data
    else
      return {}
  }  

  export const updateLessonById = async(id,title,description) =>{
    const { data, error } = await supabase
    .from('Lesson')
    .update({ Title: title, Content:description })
    .eq('LessonID', id)
    .select()

    if(error)
      return false
    else 
    return true
        
  }

/**export const createCourse = async(userId, image) =>{
 
  if(localStorage.getItem('CourseTeacherTitle').length != 0){
    if(localStorage.getItem('CourseTeacherDescription').length != 0){
      if(localStorage.getItem('CourseTeacherPrice').length != 0){
        const { data, error } = await supabase
        .from('Course')
        .insert([
        { 
            Title: localStorage.getItem('CourseTeacherTitle'),
            Description: localStorage.getItem("CourseTeacherDescription").toString(),
            Type: localStorage.getItem("CourseTeacherPrice") > 0 ? "Paid" : "Free",
            Price: Number(localStorage.getItem("CourseTeacherPrice")),
            duration: 10,
            Teacher_ID: userId.toString()
        },
        ])
        .select()
    
        if(error){
            return false;
        }else{
            sessionStorage.setItem('teacherManageCourseId',data[0].CourseID)
            let uploadImgVal = await handleUpload(userId,data[0].CourseID, image)
    
            if(uploadImgVal)
            return true;
            else
            return false
        }
      }
      return false
    }    
    return false
  }  
  return false   
}
 */