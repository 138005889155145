import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import pic_1 from "../../assets/images/pic-1.jpg"
import landing_img from "../../assets/images/Landing_img.svg"
import ic_hourglass from "../../assets/images/ic_hourglass.svg"
import ic_user from "../../assets/images/ic_user.svg"
import chatting_re from '../../assets/images/chatting_re.svg'
import online_learning_re from '../../assets/images/online_learning_re.svg'
import professor_re from '../../assets/images/professor_re.svg'
import thumb_1 from "../../assets/images/thumb-1.png"
import thumb_2 from "../../assets/images/thumb-2.png"
import thumb_3 from "../../assets/images/thumb-3.png"
import pic_2 from "../../assets/images/pic-2.jpg"
import pic_3 from "../../assets/images/pic-3.jpg"
import pic_4 from "../../assets/images/pic-4.jpg"
import { useNavigate } from 'react-router-dom';
import { getUserSession } from '../../services/user.action';

const Welcome = () => {

  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn');
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    toggleBtn.addEventListener('click', () => {
      darkMode = localStorage.getItem('dark-mode');
      if (darkMode === 'disabled') {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    let profile = document.querySelector('.header .flex .profile');

    document.querySelector('#user-btn').addEventListener('click', () => {
      profile.classList.toggle('active');
      // search.classList.remove('active');
    });

    let sideBar = document.querySelector('.side-bar');

    document.querySelector('#menu-btn').addEventListener('click', () => {
      sideBar.classList.toggle('active');
      body.classList.toggle('active');
    });

    document.querySelector('#close-btn').addEventListener('click', () => {
      sideBar.classList.remove('active');
      body.classList.remove('active');
    });

    window.addEventListener('scroll', () => {
      profile.classList.remove('active');

      if (window.innerWidth < 1200) {
        sideBar.classList.remove('active');
        body.classList.remove('active');
      }
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const checkUserSession = async () => {
      const userData = await getUserSession(); // Supposons que cette fonction récupère les données de session utilisateur
      if (userData) {
        navigate('/auth/home'); // Redirige vers la page d'accueil si la session utilisateur est vide
      }
    };
    checkUserSession();
  }, [navigate])
  
  return (
    <div className="welcome">
      <header className="header">
   
      <section className="flex">

          <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>
          
          <nav className="navbar">
            <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
            <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
            <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
          </nav>

          <div className="icons">
            <div id="menu-btn" className="fas fa-bars"></div>
            <div id="user-btn" className="fas fa-user"></div>
            <div id="toggle-btn" className="fas fa-sun"></div>
          </div>

          <div className="profile">
            <img src={pic_1} className="image" alt="" />
            <h3 className="name">No user</h3>
            <p className="role">✖️</p>
            <Link to={'/login'} className="btn">Login</Link>
            <div className="flex-btn">
                <Link to={'/register'} className="option-btn">Register</Link>
            </div>
          </div>

      </section>

    </header>
    <div className="side-bar">

        <div id="close-btn">
            <i className="fas fa-times"></i>
        </div>

        <div className="profile">
            <img src={pic_1} className="image" alt="" />
            <h3 className="name">No user</h3>
            <p className="role">✖️</p>
            <Link to={'/login'} className="btn">Login</Link>
            <div className="flex-btn">
                <Link to={'/register'} className="option-btn">Register</Link>
            </div>
          </div>

        <nav className="navbar">
            <Link to={'/about'}><i className="fas fa-question"></i><span>about</span></Link>
            <Link to={'/login'}><i className="fas fa-graduation-cap"></i><span>courses</span></Link>
            <Link to={"/contact"}><i className="fas fa-headset"></i><span>contact us</span></Link>
          </nav>

      </div>
      <section className="landing">
           <section className="landing-text" data-aos="fade-right" >
               {/*<h3>E-COURSE PLATFORM</h3>*/}
               <h1>Learning and teaching online, made easy</h1>
               <p>Gain subject knowlegdes or earn money while teaching online with Paypal.</p>
               <section className="btn_group">
                   <Link className="btn_sign_in" to={'/login'} >Sign in</Link>
                   <Link className="btn_learn_more" to={'/about'}>
                       Learn more 
                       <div id="user-btn" className="fas fa-arrow-right"></div>
                   </Link>
               </section>
               <section className="panel_info" data-aos="fade-up-right">
                   <div className="content-info">
                       <div style={{display: 'flex', alignItems: 'center'}}>
                           <img src={ic_hourglass} alt="ic_houglass" />
                           <span style={{fontWeight: 'bold', fontSize: 40}}>700</span>
                       </div>
                       <p>Hours of content </p>
                   </div>
                   <hr />
                   <div className="content-info">
                       <div style={{display: 'flex', alignItems: 'center'}}>
                           <img src={ic_user} alt="ic_user" />
                           <span style={{fontWeight: 'bold', fontSize: 40}}>575K+</span>
                       </div>
                       <p>Active Users </p>
                   </div>
               </section>
           </section>
           <img src={landing_img} alt="Landing page" style={{width: '675px', height: '577px'}} data-aos="zoom-in-up" className='landing-img' />
       </section>
       <section className="panel_info_app">
                <h3>La Quête Du Savoir Sans Frontières</h3>
                <p>Avec ITESTUDY , peu importe le domaine, trouver un moyen d’apprendre avec le sourire et la bonne humeur</p>
                <section>
                    <div data-aos="zoom-in">
                        <img src={online_learning_re} alt="Desc_App" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquam.</p>
                    </div>
                    <div data-aos="zoom-in">
                        <img src={professor_re} alt="Desc_App" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquam.</p>
                    </div>
                    <div data-aos="zoom-in">
                        <img src={chatting_re} alt="Desc_App" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquam.</p>
                    </div>
                </section>
            </section>
            <section className="noAuth_list_courses_section">
                <h3>Explorez le savoir à votre rythme <br /> avec des modules qui inspirent la croissance intellectuelle et professionnelle</h3>
                <br />
                <br />
                <div className="box-container">
                <div className="box">
                
                  <div className="thumb">
                      <img src={thumb_1} alt="" />
                      <span>10 videos</span>
                  </div>
                  <h3 className="title">complete HTML tutorial</h3>
                  <Link to={'/login'} className="inline-btn">view course</Link>
                </div>

                <div className="box">
                  
                  <div className="thumb">
                      <img src={thumb_2} alt=""/>
                      <span>10 videos</span>
                  </div>
                  <h3 className="title">complete CSS tutorial</h3>
                  <Link to={'/login'} className="inline-btn">view course</Link>
                </div>

                <div className="box">
                 
                  <div className="thumb">
                      <img src={thumb_3} alt=""/>
                      <span>10 videos</span>
                  </div>
                  <h3 className="title">complete JS tutorial</h3>
                  <Link to={'/login'} className="inline-btn">view course</Link>
                </div>
              </div>
            </section>
            <section className="noAuth_testimonials_section">
                <h3>TESTIMONIALS</h3>
                <h1>What our clients say about us.</h1>
            <div className="box-container">

              <div className="box">
                <div className="student">
                    <img src={pic_2} alt=""  style={{borderRadius: 100}}/>
                    <div>
                      <h3>john deo</h3>
                      <div className="stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              </div>

              <div className="box">
                <div className="student">
                    <img src={pic_3} alt=""  style={{borderRadius: 100}}/>
                    <div>
                      <h3>john deo</h3>
                      <div className="stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              </div>

              <div className="box">
                <div className="student">
                    <img src={pic_4} alt="" style={{borderRadius: 100}}/>
                    <div>
                      <h3>john deo</h3>
                      <div className="stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur. Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero facilis fuga. Eligendi eaque molestiae modi?</p>
              </div>
              </div>
            </section>
       <footer className="footer">

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

      </footer>
    </div>
  );
}

export default Welcome;
