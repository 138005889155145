import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { commentCours, fetchSavedCourses, likeCours } from '../../services/user.action';
import { ClipLoader } from 'react-spinners';
import { getUserSession,getUserImage } from '../../services/user.action';
import { handleUpload } from '../../services/user.action';

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [saveCourse,setSaveCourse] = useState([])
  const [likeCourse,setLikeCOurse] = useState([])
  const [commentCourse,setCommentCourse] = useState(0)
  const [userImage,setUserImage] = useState("")
  const [userSession, setUserSession] = useState({});
  const [newImage,setNewImage] = useState("");


  useEffect(() =>{
    async function fetchData() {
    try{
        setIsLoading(true)
        const Scours = await fetchSavedCourses()
        const Lcours = await likeCours()
        const Ccours = await commentCours()

          setIsLoading(false)
          setSaveCourse(Scours)
          setLikeCOurse(Lcours)
          setCommentCourse(Ccours)
          console.log(Ccours)

          
      
    }
    catch(error){
        console.error(error)
    }
    
  }
  fetchData()
},[]);


useEffect(() =>{
  const getImageUser = async() =>{
    const image = await getUserImage()
    const userSession = await getUserSession()
    setUserSession(userSession)
    setUserImage(image)
  }

  getImageUser()
},[])

function accessLikeCourses(){
  sessionStorage.setItem("Choose Course","Likes Courses")
}
function accessCommentCourses(){
  sessionStorage.setItem("Choose Course","Comment Courses")
}
function accessSavedCourses(){
  sessionStorage.setItem("Choose Course","Saved Courses")
}

  return (
    !isLoading ? (
      <>
      <section className="user-profile">

        <h1 className="heading">your profile</h1>

        <div className="info">
        <div className="user">
          {!isLoading2 ? (
            
              <div>
                {
                  newImage ? (
                    <>
                    <img src={newImage} alt="Do you want to change your profil photo?"/>
                    </>
                  ):(
                    <>
                    <img src={userImage || userSession['image'] } alt="Do you want to change your profil photo?"/>
                    </>
                  )
                }
              </div>
          ):(
            <ClipLoader
            color={'#2196F3'}
            loading={isLoading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          )
        }
        <h3>{userSession['username']}</h3>
              <p>student</p>
              <Link to={'update'} className="inline-btn">update profile</Link>
            </div>
            <div className="box-container">
        
              <div className="box">
                  <div className="flex">
                    <i className="fas fa-bookmark"></i>
                    <div>
                        <span>{saveCourse.length ? saveCourse.length : 0}</span>
                        <p>saved courses</p>
                    </div>
                  </div>
                  <Link to={'/auth/courses'} onClick={() => {accessSavedCourses()}} className="inline-btn">view courses</Link>
              </div>
        
              <div className="box">
                  <div className="flex">
                    <i className="fas fa-heart"></i>
                    <div>
                        <span>{likeCourse.length ? likeCourse.length : 0}</span>
                        <p>videos liked</p>
                    </div>
                  </div>
                  <Link to={'/auth/courses'} onClick={() => {accessLikeCourses()}} className="inline-btn">view liked</Link>
              </div>
        
              <div className="box">
                  <div className="flex">
                    <i className="fas fa-comment"></i>
                    <div>
                        <span>{commentCourse.length ? commentCourse.length : 0}</span>
                        <p>videos comments</p>
                    </div>
                  </div>
                  <Link to={'/auth/courses'} onClick={() => {accessCommentCourses()}} className="inline-btn">view comments</Link>
              </div>
        
            </div>
        </div>

      </section>
    </>
    ) : (
      <ClipLoader
        color={'#2196F3'}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
  );
}

export default Profile;
