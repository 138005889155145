import { supabase } from "../supabase";

export const getExerciceByChapter = async(newChapter) =>{
    const chapterId = newChapter ? newChapter : sessionStorage.getItem("IdChapter");
    try{
        const {data : questions,error} =  await supabase
        .from("EvaluationQuestion")
        .select('*')
        .eq("Chapter_ID",chapterId)

        if(questions){
            console.log(questions)
            return questions;
        } else {
            console.error(error.message)
        }
    }catch(error){
        console.error(error)
    }
    
}

export const deleteQuizz = async(questions) =>{
    try{
        for (const question of questions) {
           const {error} = await supabase
            .from("EvaluationQuestion")
            .delete()
            .eq('Chapter_ID',question.Chapter_ID)

        }
        return true; 
    }
    catch(error){
        console.error(error)
        return false
    }
}

export const createQuizz = async(questions) =>{
    try{
        for (const question of questions) {
            const { data, error } = await supabase
            .from('EvaluationQuestion')
            .insert([
            {   
                QuestionText: question.QuestionText,
                CorrectAnswer: question.CorrectAnswer,
                Options: question.Options,
                Chapter_ID: question.Chapter_ID
            },
            ])
            .select()
        }
        return true;      
    }catch(error){ 
        console.error(error)
        return false
    }
}