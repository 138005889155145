import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <section className="error">
      <div className="content">
        <h3>404</h3>
        <h1>Page not found</h1>
        <p>Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="button-container">
          <Link className='link' to={"/auth/home"}>Go back home</Link>
          <Link className='link' to={"/auth/contact"}>Contact support <span aria-hidden="true">&rarr;</span></Link>
        </div>
      </div>
    </section>
  );
}

export default Error;
