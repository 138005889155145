import React, { useEffect,useState } from 'react';
import Chart from 'chart.js/auto';
import vector from "../../../assets/images/Vector.svg"
import vector_1 from "../../../assets/images/Vector_1.svg"
import timelapse from "../../../assets/images/timelapse.svg"
import shield_search from "../../../assets/images/shield-search.svg"
import { Link } from 'react-router-dom';
import { supabase } from '../../../supabase';
import { getTeacherCourse } from '../../../services/course.action';
import { deleteCourse } from '../../../services/course.action';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination';

const CoursesDashboard = () => {

  const[courses,setCourses] = useState([])
  const[currentPage,setCurrentPage] = useState(1)
  const[coursPerPage] = useState(5)
  const navigate = useNavigate()

  useEffect(() => {
    // Performance charts
    const createChart = (id, data) => {
      const ctx = document.getElementById(id).getContext('2d');
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Performance'],
          datasets: [{
            data: [data, 100 - data],
            backgroundColor: ['#4caf50', '#e0e0e0'],
            borderWidth: 0
          }]
        },
        options: {
          cutout: '70%',
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      });
    };

    const getCourses = async() => {
      let teacherCourses = await getTeacherCourse()
      setCourses(teacherCourses)
    }

    getCourses().then( () =>{
      console.log(courses)
      subscribeToCourses()
      courses.forEach(
        (course) =>{
          createChart(`performanceChart${course.cour.CourseID}`, 350);
        }
      )
    }
    )
  }, [courses]);

  const deletedCourse = async(idCourse) =>{
    const isDelete = await deleteCourse(idCourse)

    if(isDelete){
      alert("Course is successfully")
    }else{
      alert("Error during the suppression")
    }
  }

  
const subscribeToCourses = () =>{
  const channel =  supabase.channel('custom-all-channel')
.on(
  'postgres_changes',
  { event: '*', schema: 'public', table: 'Course' },
  (payload) => {
    console.log(courses)
    setCourses([payload.new, ...courses])

  }
  
)
.subscribe()
return () =>{
 supabase.removeChannel(channel)
}
}

function showTeacher(teacherName,teacherImg,teacherSpecialization){
  sessionStorage.setItem('teacherName',teacherName)
  sessionStorage.setItem('teacherImg',teacherImg)
  sessionStorage.setItem('specialization',teacherSpecialization)
}

function showCour(id,image,title,date,type,chapterNumber,price,teacherName,teacherImg,teacherSpecialization){
  showTeacher(teacherName,teacherImg,teacherSpecialization)
  sessionStorage.setItem('imageCourse',image)
  sessionStorage.setItem('titleCourse',title)
  sessionStorage.setItem('dateCourse',date)
  sessionStorage.setItem('chapterNumber',type === "Free" ? chapterNumber : `${price} XAF`)
  localStorage.setItem("teacherManageCourseId",id)
  sessionStorage.setItem("IdCourse",id)
}

//Get current cours
const indexOfLastCours = currentPage * coursPerPage
const indexOfFirstCours = indexOfLastCours - coursPerPage;
const currentCours = courses.slice(indexOfFirstCours,indexOfLastCours)

const paginate = (pageNumber) => setCurrentPage(pageNumber)
  return (
    <div className="courses-dashboard">
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
        <h1>Courses</h1>
        <Link style={{ backgroundColor: '#4caf50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px' }} to={'/auth/teacher/dashboard/course/create'} onClick={() => {
          localStorage.removeItem("teacherManageCourseId");
          sessionStorage.setItem("manageMode","add")}}>Add Courses</Link>
      </div>
      <div className="filters" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px 0', borderBottom: '1px solid #ddd' }}>
        <button style={{ backgroundColor: '#007bff', color: 'white',cursor:'pointer', padding: '10px', border: 'none', borderRadius: '5px',display: "flex", alignItems: "center", justifyContent: "space-between" }} className='neumorphic'><img src={vector} alt='vector' />Les Plus Populaire</button>
        <button style={{ backgroundColor: '#fff', color: '#333',cursor:'pointer', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', display: "flex", alignItems: "center", justifyContent: "space-between" }} className='neumorphic'><img src={timelapse} alt='vector' />Les Plus Recent</button>
        <button style={{ backgroundColor: '#fff', color: '#333',cursor:'pointer', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', display: "flex", alignItems: "center", justifyContent: "space-between" }} className='neumorphic'><img src={shield_search} alt='vector' />En Cours D'Examen</button>
        <button style={{ backgroundColor: '#fff', color: '#333',cursor:'pointer', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', display: "flex", alignItems: "center", justifyContent: "space-between" }} className='neumorphic'><img src={vector_1} alt='vector' />Filter</button>
      </div>
      
      <div className="courses-list" style={{ padding: '20px' }}>
      {
        currentCours && (
          <>
            {
              currentCours.map(
                course =>(
                  <>
                  {/* {[1, 2].map((item, index) => ( */}
                  <div /*key={index}*/ className="course-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 10px', borderBottom: '1px solid #ddd', margin: "20px 0" }}>
                  <div className="course-info neumorphic" onClick={() => 
                  {navigate("/auth/course/details/"+course.cour.CourseID)
                  showCour(course.cour.CourseID,course.cour.Thumbnail,course.cour.Title,course.cour.created_at,course.cour.Type,5,course.cour.Price,course.Name,course.Thumbnail,course.Specialization)
                  }} style={{ display: 'flex', alignItems: 'center', backgroundColor: "var(--white)" , cursor: 'pointer' }}> 
                    <img src={course.cour.Thumbnail} alt="Course" style={{ width: '150px', height: '100px', marginRight: '10px' }} />
                    <div>
                      <h2 style={{ margin: 0 }}>{course.cour.Title}</h2>
                      <p style={{ margin: 0 }}>{course.cour.Description.substring(0,50)}...</p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>2K</span> <i className="fas fa-users" style={{ margin: '0 5px' }}></i>
                        <span>{course.totalLikes}</span> <i className="fas fa-heart" style={{ margin: '0 5px' }}></i>
                      </div>
                    </div>
                    </div> 
        
                  <div className='neumorphic' style={{backgroundColor: "var(--white)", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 10px"}}>
                  <div className="course-chapters">
                  <span>Nombre de chapitres ({course.lessonCount})</span>
                  <div style={{ display: 'flex' }}>
                    {Array.from({ length: Math.min(course.lessonCount, 5) }).map((_, index) => (
                      <img
                        key={index}
                        src={course.cour.Thumbnail}
                        alt={`Chapter ${index + 1}`}
                        style={{ width: '30px', height: '30px', marginRight: '5px' }}
                      />
                    ))}
                  </div>
                </div>
                  <div className="course-performance" style={{ width: '100px', textAlign: 'center' }}>
                    <canvas id={`performanceChart${course.cour.CourseID}`} width="100" height="100"></canvas>
                    <span>Performance</span>
                  </div>
                  </div>
                  <div className="course-actions" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>Actions</h3>
                    <Link to="/auth/teacher/dashboard/course/create">
                    <button style={{ backgroundColor: '#ff9800', color: 'white', padding: '5px 10px', marginBottom: '5px', border: 'none', borderRadius: '5px', width: "100%" , cursor: 'pointer' }} onClick={() => {sessionStorage.setItem("manageMode","edit")
                      localStorage.setItem("teacherManageCourseId",course.cour.CourseID)
                    }}>Edit</button>
                    </Link>
                    <button style={{ backgroundColor: '#f44336', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px', width: "100%" , cursor: 'pointer'  }} onClick={() => deletedCourse(course.cour.CourseID)}>Delete</button>
                  </div>
                  </div>
                  {/* ))} */}
                </>
                )
              )
            }
          </>
        )
      }
      </div>
      <Pagination coursPerPage={coursPerPage} totalCours={courses.length} paginate={paginate}/>
    </div>
  );
};

export default CoursesDashboard;