import { URL_APP } from "..";
import { supabase } from "../../supabase";

export const signInWithGithub = async (onSuccess, onError) => {
    try {
      const { user, session, error } = await supabase.auth.signInWithOAuth({
        provider: 'github',
        options:{
          redirectTo : URL_APP + "auth/home",
        },
      });
      if (error) {
        console.error('Erreur lors de la connexion avec Github:', error.message);
        if (onError) {
          onError(error.message);
          return { success: false }
        }
      } else {
        console.log('Connexion avec GitHub réussie:', user);
        localStorage.setItem("isAuth", JSON.stringify(true));
        if (onSuccess) {
          onSuccess({ user, session });
        }
        return { success: true }
      }
    } catch (error) {
      console.error('Erreur inattendue lors de la connexion avec GitHub:', error.message);
      if (onError) {
        onError('Une erreur inattendue s\'est produite.');
      }
      return { success: false }
    }
  };