import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FacebookLoginButton } from "react-social-login-buttons";
import party from ".././../assets/images/party.png"
import { XLoginButton } from 'react-social-login-buttons';
import { LinkedInLoginButton } from 'react-social-login-buttons';
import ConfettiExplosion from 'react-confetti-explosion';

const Success = () => {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);

  const handleRating = (rate) => {
    setRating(rate);
    setSubmitted(true);
  };

  useEffect(() => {
    // Désactiver l'animation de confettis après quelques secondes
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000); // Modifier le délai selon vos besoins

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className="course-completed">
      <div className="box-container">
        {showConfetti && <ConfettiExplosion />}
        <img src={party} alt="PartyImage" />
        <h2 style={{color: 'var(--black)', fontSize: 25}}>Congratulations! You have completed the course.</h2>
        <Link to="/auth/home" style={{ width: 300}} className="btn btn-success">GO home</Link>

        <div className="rating-section">
          <h3 style={{color: 'var(--black)', fontSize: 25}}>Rate this course:</h3>
          {submitted ? (
            <p style={{color: 'var(--black)', fontSize: 20}}>Thank you for your rating! You gave {rating} star(s).</p>
          ) : (
            <div className="rating-stars">
              {[1, 2, 3, 4, 5].map(star => (
                <span 
                  key={star} 
                  className={`star ${rating >= star ? 'selected' : ''}`} 
                  onClick={() => handleRating(star)}
                  style={{color: 'var(--black)', fontSize: 25}}
                >
                  ★
                </span>
              ))}
            </div>
          )}
        </div>

        <div className="share-section">
          <h3  style={{color: 'var(--black)', fontSize: 25}}>Share this course:</h3>
          <div className="share-buttons">
            <FacebookLoginButton>
              <a 
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{color: 'var(--white)'}}
              >
                Share on Facebook
              </a>
            </FacebookLoginButton>
            <XLoginButton>
              <a 
                href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=I completed a great course!`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{color: 'var(--white)'}}
              >
                Share on Twitter
              </a>
            </XLoginButton>
            <LinkedInLoginButton>
              <a 
                href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{color: 'var(--white)'}}
              >
                Share on LinkedIn
              </a>
            </LinkedInLoginButton>
            {/* Add buttons for other social networks here */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
