import React, { } from 'react';
import { useNavigate } from 'react-router-dom';
import Graph1 from '../../../components/graph1';
import Graph2 from '../../../components/graph2';
import Commentaire from '../../../components/commentaire';
import './acceuil.scss'

const Dashboard = () => {
  const navigate = useNavigate()
  return (
    <>
      <section className="home-grid">
        <div className="heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ fontSize: "2.5rem" }}>quick options</h1>
          <button id='btn-switch' style={{ backgroundColor: "#06BF3A", textTransform: "capitalize", paddingLeft: 20, paddingRight: 20, color: "var(--white)", cursor: "pointer", borderRadius: 5 }} onClick={() => {navigate("/auth/teacher/dashboard/courses")}}>Manage Course</button>
          <button id='btn-switch' style={{ backgroundColor: "#06BF3A", textTransform: "capitalize", paddingLeft: 20, paddingRight: 20, color: "var(--white)", cursor: "pointer", borderRadius: 5 }} onClick={() => {navigate("/auth/home")}}>switch to student</button>
        </div>

        <div className="box-container" style={{ gridTemplateColumns: "repeat(auto-fit, 20.5rem)" }}>

          <div className="box" style={{ width: 180, borderRadius: 10 }}>
            <span className='value'>24</span>
            <h1 className='title'>
              <i className="fas fa-graduation-cap"></i>
              <span>courses</span>
            </h1>
          </div>
          <div className="box" style={{ width: 180, borderRadius: 10 }}>
            <span className='value'>460</span>
            <h1 className='title'>
              <i className="fas fa-user"></i>
              <span>students</span>
            </h1>
          </div>
          <div className="box" style={{ width: 180, borderRadius: 10 }}>
            <span className='value'>50K</span>
            <h1 className='title'>
              <i className="fas fa-dollar"></i>
              <span>money</span>
            </h1>
          </div>
          <div className="box" style={{ width: 180, borderRadius: 10 }}>
            <span className='value'>2K</span>
            <h1 className='title'>
              <i className="fas fa-heart"></i>
              <span>likes</span>
            </h1>
          </div>
          <div className="box" style={{ width: 180, borderRadius: 10 }}>
            <span className='value'>460</span>
            <h1 className='title'>
              <i className="fas fa-comment"></i>
              <span>comments</span>
            </h1>
          </div>

        </div>
      </section>     
      <div className="graphContainer" style={{borderRadius: "40px"}}>
        <div className="container">
          <Graph1/>
          <Graph2/>
          <Commentaire/>
        </div>
      </div>
    </>
  );
}

export default Dashboard;