export const data=[
    {
        name: 'Lun',
        uv: 4000,
        nbre_visiteurs: 2400,
        amt: 200,
      },
      {
        name: 'Mar',
        uv: 3000,
        nbre_visiteurs: 1398,
        amt: 2210,
      },
      {
        name: 'Mer',
        uv: 2000,
        nbre_visiteurs: 9800,
        amt: 2290,
      },
      {
        name: 'Jeu',
        uv: 2780,
        nbre_visiteurs: 3908,
        amt: 2000,
      },
      {
        name: 'Ven',
        uv: 1890,
        nbre_visiteurs:4800,
        amt: 2181,
      },
      {
        name: 'Sam',
        uv: 2390,
        nbre_visiteurs: 3800,
        amt: 2500,
      },
      {
        name: 'Dim',
        uv: 3490,
        nbre_visiteurs: 4300,
        amt: 2100,
      },
      
];