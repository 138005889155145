import React from "react";
import { Link } from 'react-router-dom';
import { supabase } from "../supabase";
import { formatDate } from "./utils";
import thumb_1 from "../assets/images/thumb-1.png"



export const submitCourse = async(id_course) =>{
    try{
        const { data, error } = await supabase
        .from('Course')
        .update({ isPublish: true })
        .eq('CourseID', id_course)
        .select()

        if(error)
            return false
        else
            return true
                
    }catch(error){
        console.log(error)
        return false
    }
}

export const getTeacherCourse = async() =>{
    try{
        const {data,error} = await supabase
        .from("Course")
        .select("*")
        .eq('Teacher_ID',JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)

        const sortedData = data.sort((a,b) => new Date(a.created_at) - new Date(b.created_at));


        if(data){
            console.log(sortedData)   
            const updateData = await Promise.all(sortedData.map(async (cour) =>{
                const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
                const {lessonCount,courseRating,totalLikes} = await fetchLessonCount(cour.CourseID);

                return {cour,...teacherNameAndThumbnail,lessonCount,courseRating,totalLikes}
            }));
            console.log(updateData)
            return updateData;
        }
        else{
            console.error(error)
            return []
        }
    }
    catch(error){
        console.error(error)
        return []
    }
}

export const getAllCourse = async() =>{
    try{
        const {data,error} = await supabase
        .from("Course")
        .select("*")
        .eq("isPublish",true)

        const sortedData = data.sort((a,b) => new Date(a.created_at) - new Date(b.created_at));


        if(data){
            console.log(sortedData)   
            const updateData = await Promise.all(sortedData.map(async (cour) =>{
                const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
                const {lessonCount,courseRating} = await fetchLessonCount(cour.CourseID);
                return {cour,...teacherNameAndThumbnail,lessonCount,courseRating}
            }));
            return updateData;
        }
        else{
            console.error(error)
            return error
        }
    }
    catch(error){
        console.error(error)
        return error
    }
    
}

export const mapIdToNameAndThumbnail = async(Id) =>{
    const { data, error } = await supabase
    .from('Teacher')
    .select('Name,Thumbnail,Specialization')
    .eq('TeacherID',Id)
    .single()

    if(data){
        return data
    }
}


export const fetchLessonsTotalLiked = async(idChapter) =>{
    try{
        const {data: lessons,error} = await supabase
        .from('Lesson')
        .select('TotalOfLiked')
        .eq("Chapter_ID",idChapter)

        let integerLesson = 0;
        if(lessons){
            lessons.forEach(lesson => {
                integerLesson +=  lesson.TotalOfLiked;
            })
             
            let length = lessons.length
            return {integerLesson,length};
        }
    }
    catch(error){
        console.error(error)
    }
}

// export const fetchLessonsTotalCOmment = async(idChapter) =>{
//     try{
//         const {data: lessons,error} = await supabase
//         .from('Lesson')
//         .select('TotalOfLiked')
//         .eq("Chapter_ID",idChapter)

//         let integerLesson = 0;
//         if(lessons){
//             lessons.forEach(lesson => {
//                 integerLesson +=  lesson.TotalOfLiked;
//             })
             
//             let length = lessons.length
//             return {integerLesson,length};
//         }
//     }
//     catch(error){
//         console.error(error)
//     }
// }

function maxLikesPerLecon() {
    return 5;  
}


export const fetchLessonCount = async (id) => {
    try {
        const { data: chapters, error } = await supabase
            .from('Chapter')
            .select('ChapterID')
            .eq('Course_ID', id);

        if (error) {
            console.error(error);
            return { lessonCount: 0, courseRating: 0 };
        } else {
            let totalLikes = 0;
            let totalLessons = 0;

            for (const chapter of chapters) {
                const { integerLesson, length } = await fetchLessonsTotalLiked(chapter.ChapterID);
                totalLikes += integerLesson;
                totalLessons += length;
            }

            if (totalLessons === 0) {
                return { lessonCount: chapters.length, courseRating: 0 , totalLikes};  // Évite la division par zéro
            }

            let noteMoyenne = totalLikes / totalLessons;

            // Normalisation de la note sur une échelle de 5
            let noteSur5 = (noteMoyenne / maxLikesPerLecon()) * 5;

            noteSur5 = Math.round(noteSur5);
            noteSur5 = Math.max(Math.min(noteSur5, 5), 1);

            return { lessonCount: chapters.length, courseRating: noteSur5, totalLikes };  // S'assure que la note ne dépasse pas 5
        }
    } catch (error) {
        console.error(error);
        return { lessonCount: 0, courseRating: 0 };
    }
};



export const getTagsCourses = async(value) =>{

    try{
        const subjectid = await subjectID(value)

        const Cours = await Promise.all(subjectid.map(async(Cour) =>{
            const cour = await getCoursesFormSubjectID(Cour.SubjectID)
            return cour
        }))
        return Cours
        
    }
    catch(error){
        console.error(error)
    }
    
}

const subjectID = async(value) =>{

    const { data,error } = await  supabase
    .from('Subject')
    .select('SubjectID')
    .contains('Tags', [value])

    return data
}

const getCoursesFormSubjectID = async(value) =>{
    try{
        const { data,error } = await  supabase
        .from('Subject_Course')
        .select('Course_ID')
        .eq('Subject_ID', value)

        if(data){
            const Cours = await Promise.all(data.map(async (Cour) =>{
                const cour = await getPublishCourseFormCourseID(Cour.Course_ID)
                const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
                const {lessonCount,courseRating} = await fetchLessonCount(cour.CourseID);
                return {cour, ...teacherNameAndThumbnail, lessonCount,courseRating};
            }));
            return Cours
        }
        else{
            console.error(error)
            return error
        }
    }
    catch(error){
        console.error(error)
                return error
    }
}

const getPublishCourseFormCourseID = async(Id) =>{
    try{
        let { data, error } = await supabase
        .from('Course')
        .select('*')
        .eq('CourseID',Id)
        .eq("isPublish",true)
        .single()

        return data
    }
    catch(error){
        console.error(error)
    }
}

export const getCourseFormCourseID = async(Id) =>{
    try{
        const { data, error } = await supabase
        .from('Course')
        .select('*')
        .eq('CourseID',Id)
        .single()

        return data
    }
    catch(error){
        console.error(error)
        return {}
    }
}

export const getChaptersFormCour = async(id) =>{
    const { data, error } = await supabase
    .from("Chapter")
    .select("*")
    .eq('Course_ID', id)
}

export const deleteCourse = async(idCourse) =>{
    
    const { error } = await supabase
    .from('Course')
    .delete()
    .eq('CourseID', idCourse)

    if(error){
       return false
    }else{
       return true
    }
        
}


export const CardCourse = (props)=>{
    function showTeacher(){
        console.log(props.teacherName)
        sessionStorage.setItem('teacherName',props.teacherName)
        sessionStorage.setItem('teacherImg',props.teacherImg)
        sessionStorage.setItem('specialization',props.teacherSpecialization)
    }

    function showCour(){
        showTeacher()
        sessionStorage.setItem('imageCourse',props.image)
        sessionStorage.setItem('titleCourse',props.title)
        sessionStorage.setItem('dateCourse',props.date)
        sessionStorage.setItem('chapterNumber',props.type === "Free" ? props.chapterNumber : `${props.price} XAF`)
    }

    function getIdCourse(){
        sessionStorage.setItem("IdCourse",props.id)
      }

    return(
        
    <>
<div className="box" onClick={() => getIdCourse()}>
    <div className="tutor">
        <Link to={'/auth/teacher/profile'} onClick={() => showTeacher()}><img src={props.teacherImg} alt="" /></Link>
        <div className="info" style={{marginTop: 0, flexDirection: "column", alignItems: "flex-start"}}>
            <Link to={'/auth/teacher/profile'} onClick={() => showTeacher()}><h3>{props.teacherName}</h3></Link>
            <span>{formatDate(props.date)}</span>
        </div>
        </div>
        <div className="thumb">
        <img src={props.image} alt=""/>
        <span>{props.type === "Free" ? "Free" : `${props.price} XAF`}</span>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h3 className="title">{props.title}</h3>
        <div id="stars-home">
        <div className="stars">
        {Array.from({ length: props.notation }, (_, index) => (
                <i className="fas fa-star"></i>
        ))}
        {Array.from({ length: 5 - props.notation }, (_, index) => (
                <i className="fas fa-star" style={{color: '#CCC'}}></i>
        ))}
        </div>
        <span style={{fontSize: '15px', fontWeight: 'bold'}}>Free</span>
        </div>
        </div>
        <Link to={'/auth/course/details/'+props.id} onClick={() => showCour()}  className="inline-btn">view course</Link>
    </div>
        
    </>             
    )
}