import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { CoursComment, CoursLiked, SavedCours } from '../../services/user.action';
import { CardCourse, getAllCourse, getTagsCourses } from '../../services/course.action';

const Courses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [Cours,setCours] = useState([])

  useEffect(() =>{
    setIsLoading(true)
    const fetchRightCourse = async() =>{
      switch (sessionStorage.getItem("Choose Course")) {
        case "Likes Courses":
          const likeCours = await CoursLiked()
          console.log(likeCours)
          setCours(likeCours)
          setIsLoading(false)
          break;
        case "Comment Courses":
          const commenCours = await CoursComment()
          setCours(commenCours)
          setIsLoading(false)
          console.log("commentaire")
          break;
          
        case "Saved Courses":
          const savedCours = await SavedCours()
          setIsLoading(false)
          setCours(savedCours)
          break;
        case "Our Courses":
          const cours = await getAllCourse()
          setCours(cours)
          console.log(cours)
          setIsLoading(false)
          break;
        default:
          const tagCours = await getTagsCourses(sessionStorage.getItem("Choose Course").toString().toLowerCase())
          setCours(tagCours[0])
          setIsLoading(false)
    }
    
    
    }
    fetchRightCourse()
  },[])

  return (
    !isLoading ? (
    <>
      <section className="courses">

        <h1 className="heading">{sessionStorage.getItem("Choose Course")}</h1>

        <div className="box-container">

        <div className="box-container">
        {
              Cours && (
                <>
                  {
                    Cours.map(
                      cours =>(
                        <>
                          <CardCourse 
                            key={cours.cour.CourseID}
                            id={cours.cour.CourseID}
                            teacherName={cours.Name}
                            teacherImg={cours.Thumbnail}
                            type={cours.cour.Type}
                            price={cours.cour.Price}
                            chapterNumber={cours.lessonCount}
                            teacherSpecialization={cours.Specialization}
                            notation = {cours.courseRating}
                            date={cours.cour.created_at}
                            image={cours.cour.Thumbnail}
                            title={cours.cour.Title}
                          />
                        </>
                      )
                    )
                  }
                </>
              )
        }
                    
        </div>
        </div>

      </section>

    </>
     ): (
      <ClipLoader
        color={'#2196F3'}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      )
  );
}

export default Courses;
