import { supabase } from "../../supabase";

export const logout = async () => {
  try {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error('Erreur lors de la déconnexion dans Supabase Auth :', error.message);
      return { success: false, error: error.message };
    }

    // Nettoyer le localStorage
    localStorage.removeItem('supabase_user');
    localStorage.removeItem('supabase_session');
    localStorage.setItem("isAuth", JSON.stringify(false));

    console.log('Utilisateur déconnecté avec succès.');
    return { success: true };
  } catch (error) {
    console.error('Erreur lors de la déconnexion :', error.message);
    return { success: false, error: error.message };
  }
};