import React, { useState,useEffect } from 'react';
import { CardChapter } from '../../services/chapter.action';
import { Link, useParams } from 'react-router-dom';
import { supabase } from '../../supabase';
import { formatDate } from '../../services/utils'

const CourseDetails = () => {
  const [isLoad,setLoad] = useState(false)
  const [saveCourse,setsaveCourse] = useState(null)
  const [chapters,setChapters] = useState()
  const [courseDescription, setCourseDescription] = useState()
  const userId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id
  const { id } = useParams()

  useEffect(() =>{
    verifSavedCourse();
    fetchChaptersDetails();
    fetchDescription();
    console.log(saveCourse)
  },[])

  const verifSavedCourse = async() =>{
    const {data,error} = await supabase
    .from("User_Cours")
    .select("*")
    .eq("User_ID",userId)
    .eq("Course_ID",id)

    console.log(data)
    if(data.length > 0) {
      setsaveCourse(data[0].courseSaved)
      console.log(data[0].courseSaved)
        return true
    }
    else{
      setsaveCourse(false)
      console.log(false)
        return false
    }
}

  const fetchDescription =  async () =>{
    const {data, error} = await supabase
    .from("Course")
    .select('Description')
    .eq('CourseID',id)
    .single()

    if(data){
        setCourseDescription(data.Description)
    }
  }

  const savedCourse = async(e) =>{
    e.preventDefault()
    const saved = await verifSavedCourse();
    console.log(saved + " bool")
    try {
      if (saved === true ) {
        if (saveCourse === true) {
          await supabase
            .from('User_Cours')
            .update({ "courseSaved": false })
            .eq('User_ID', userId)
            .eq("Course_ID",id)
            .select();
          setsaveCourse(false);
        } else {
          await supabase
            .from('User_Cours')
            .update({ "courseSaved": true })
            .eq('User_ID', userId)
            .eq("Course_ID",id)
            .select();
          setsaveCourse(true);
        }
      }else{
        const { data, error } = await supabase
          .from('User_Cours')
          .insert([
            {
              "User_ID": userId,
              "Course_ID": id,
              "courseSaved": true
            }
          ])
          .select();
        setsaveCourse(true);
        console.log(saved.toString() + " first")
      }
    } catch (error) {
      console.error(error); // Handle errors from both verifSavedCourse and update/insert
    }
  }

  const fetchChaptersDetails = async() =>{
    const { data, error } = await supabase
        .from("Chapter")
        .select("*")
        .eq('Course_ID', id)

    if (error) {
        console.log(error);
        return null; 
    }
    console.log(data)
    setChapters(data)
    return data;
    
  }


  const teacherName = sessionStorage.getItem('teacherName')
  const teacherImage = sessionStorage.getItem('teacherImg')
  const imgCours = sessionStorage.getItem('imageCourse')
  const titleCours = sessionStorage.getItem('titleCourse')
  const dateCours = sessionStorage.getItem('dateCourse')
  const chapterNumber = sessionStorage.getItem('chapterNumber')

  return (
    <>
      <section className="playlist-details">

        <h1 className="heading">course details</h1>

        <div className="row">

            <div className="column">
              <form action="" className="save-playlist">
                {
                  saveCourse ? (
                    <button className='btnActive' type="submit" onClick={savedCourse}><i className="far fa-floppy-disk"></i> <span>course saved!</span></button>
                  ) : saveCourse === false ? (
                    <button type="submit" onClick={savedCourse}><i className="far fa-bookmark"></i> <span>save course</span></button>
                  ) : (
                    <button type="submit" ><span>save course</span></button>
                  )
                }
                  
              </form>
        
              <div className="thumb">
                  <img src={imgCours} alt=""/>
                  <span>{chapterNumber}</span>
              </div>
            </div>
            <div className="column">
              <div className="tutor">
              <Link to={'/auth/teacher/profile'}><img src={teacherImage} alt=""/></Link>
                  <div>
                  <Link to={'/auth/teacher/profile'}><h3>{teacherName}</h3></Link>
                    <span>{formatDate(dateCours)}</span>
                  </div>
              </div>
        
              <div className="details">
                  <h3>{titleCours}</h3>
                  {
                    courseDescription && (
                    <p>{courseDescription.substring(0,375)}...</p>
                    )
                  }
                  <Link to={'/auth/teacher/profile'} className="inline-btn">view profile</Link>
              </div>
            </div>
        </div>

      </section>

      <section className="playlist-videos">

        <h1 className="heading">course chapters</h1>

        <div className="box-container">

        {
              chapters && (
                <>
                  {
                    chapters.map(
                      chapter =>(
                        <>
                          <CardChapter
                            key={chapter.ChapterID}
                            id= {chapter.ChapterID}
                            image = {imgCours}
                            title = {chapter.Title}
                          />
                        </>
                      )
                    )
                  }
                </>
              )
        }

        </div>

      </section>
    </>
  );
}

export default CourseDetails;
