import React, { useEffect, useState } from 'react';
import { CardCourse, commentCours, getTeacherCourse, getVideoLength, likeCours } from '../../services/teacher.action';

const TeacherProfile = () => {

   const [videoLength,setVideosLength] = useState(0)
   const [coursLength,setCoursLength] = useState(0)
   const [cours,setCours] = useState()
   const [commentLength,setCommentLength] = useState(0)
   const [likeLength,setLikeLength] = useState(0)
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      console.log(!isLoading ?? "La barre de chargement n'est pas active!")
   })

   useEffect(() =>{
      setIsLoading(true)
      const fetchCourse = async() =>{
         const course = await getTeacherCourse()
         setCoursLength(course.length)
         setCours(course)
         const likes = await likeCours()
         setLikeLength(likes.length)
         const comments = await commentCours()
         setCommentLength(comments.length)
         const videos = await getVideoLength()
         setVideosLength(videos.length)
         setIsLoading(true)
      } 
      fetchCourse()
   },[])

  return (
    <>
     
   <section className="teacher-profile">

         <h1 className="heading">profile details</h1>

         <div className="details">
            <div className="tutor">
            <img src={sessionStorage.getItem('teacherImg')} alt=""/>
               <h3>{sessionStorage.getItem('teacherName')}</h3>
               <span>{sessionStorage.getItem('specialization')}</span>
            </div>
            <div className="flex">
            <p>total courses : <span>{coursLength ? coursLength : 0}</span></p>
               <p>total videos : <span>{videoLength ? videoLength : 0}</span></p>
               <p>total likes : <span>{likeLength ? likeLength : 0}</span></p>
               <p>total comments : <span>{commentLength ? commentLength : 0}</span></p>
            </div>
         </div>

      </section>

      <section className="courses">

         <h1 className="heading">our courses</h1>

         <div className="box-container">

         {
            cours && (
               <>
                 {
                   cours.map(
                     cour =>(
                       <>
                         <CardCourse
                           key={cour.cour.CourseID}
                           id={cour.cour.CourseID}
                           teacherName={sessionStorage.getItem('teacherName')}
                           teacherImg={sessionStorage.getItem('teacherImg')}
                           chapterNumber={cour.lessonCount}
                           teacherSpecialization={sessionStorage.getItem('specialization')}
                           notation = {cour.courseRating}
                           date={cour.cour.created_at}
                           image={cour.cour.Thumbnail}
                           title={cour.cour.Title}
                         />
                       </>
                     )
                   )
                 }
               </>
             )
         }

         </div>

      </section>
    </>
  );
}

export default TeacherProfile;
