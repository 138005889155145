import { supabase } from "../supabase"
import pic_1 from "../assets/images/pic-1.jpg"
import { fetchLessonCount, mapIdToNameAndThumbnail } from "./course.action";
  

export const getUserSession = async () => {
  const sessionAuth = await supabase.auth.getSession();
  
  // Vérifier si la session est valide
  if (sessionAuth && sessionAuth.data.session.user) {
    const { email, user_metadata, identities } = sessionAuth.data.session.user;
    let username = ''; // Initialiser le nom d'utilisateur à une chaîne vide
    let image = pic_1; // Initialiser l'image à une chaîne vide
    let identifier = sessionAuth.data.session.user.id
    // Si l'utilisateur est authentifié avec Google
    if (identities.some(identity => identity.provider === 'google')) {
      const googleIdentity = identities.find(identity => identity.provider === 'google');
      if (googleIdentity) {
        username = googleIdentity.identity_data.name;
        image = googleIdentity.identity_data.picture;
      }
    }
    // Si l'utilisateur est authentifié avec Github
    else if (identities.some(identity => identity.provider === 'github')) {
      const githubIdentity = identities.find(identity => identity.provider === 'github');
      if (githubIdentity) {
        username = githubIdentity.identity_data.full_name;
        image = githubIdentity.identity_data.avatar_url;
      }
    }
    // Si l'utilisateur est authentifié avec Email et mot de passe
    else {
      // Si app_metadata existe et contient le champ username
      if (user_metadata && user_metadata.name) {
        username = user_metadata.name;
      }
    }

    console.log({ username, email, image })
    return { username, email, image, identifier };
  } else {
    // Si la session n'est pas valide ou si l'utilisateur n'est pas connecté, retourner null
    return null;
  }
};

export const updateUser = async(email,password,name) =>{
  try{
      const { data, error } = await supabase.auth.updateUser({
          email: email.toString(),
          password: password.toString()
        })

        const { data: metadata, error: errordata } = await supabase.auth.updateUser({
          data :{ name : name,full_name: name}
        })

        if(error || errordata){
          console.log(error || errordata)
          return {error}
        }
        else{
          console.log(data)
          return data
        }
  }
  catch(error){
      console.error(error)
  }
}

export const getUserStatus = async () =>{
    const { data,error } = await supabase.from('users')
    .select('role')
    .eq('id',JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id.toString())
    .single()

    if(error){
        console.error(error.message)
    }else{
        return data.role
    }
}

export const getUserImage = async() =>{
    const sessionAuth = await supabase.auth.getSession();

    if(sessionAuth){
        const { data,error } = await supabase
        .from('users')
        .select("thumbnail")
        .eq('id',sessionAuth.data.session.user.id)
        .single()
        
        if(error){
            console.error(error)
            return null
        }else{
            return data.thumbnail
        }
    }else{
        return null
    }
}

export const handleUpload = async (image) => {
    let { identifier } = await getUserSession() 
    if (!image) return;
  
    const response = await fetch(image);
    const blob = await response.blob();
    const filePath = `${identifier}/${new Date().getTime()}.${blob.type.split('/')[1]}`;
  
    const { data, error } = await supabase.storage
      .from('userProfilPhoto')
      .upload(filePath, blob);
      
    if (error) {
      console.error('Error uploading image:', error);
      return false
    } else {
      const publicUrl = supabase.storage
        .from('userProfilPhoto')
        .getPublicUrl(filePath).data.publicUrl;
  
        const { data, error } = await supabase
        .from('users')
        .update({ thumbnail: publicUrl.toString() })
        .eq('id', identifier.toString())
        .select()
        
  
      if (error) {
        console.error('Error inserting image into database:', error);
        return false
      } else {
        console.log('Image uploaded and saved successfully:', publicUrl);
        return true
      }
    }
  };

/**
 const sessionAuth = await supabase.auth.getSession();
  
  // Vérifier si la session est valide
  if (sessionAuth && sessionAuth.user) {
    const { email, app_metadata, identities } = sessionAuth.user;
    let username = ''; // Initialiser le nom d'utilisateur à une chaîne vide
    let image = ''; // Initialiser l'image à une chaîne vide

    // Si l'utilisateur est authentifié avec Google
    if (identities.some(identity => identity.provider === 'google')) {
      const googleIdentity = identities.find(identity => identity.provider === 'google');
      if (googleIdentity) {
        username = googleIdentity.identity_data.name;
        image = googleIdentity.identity_data.picture;
      }
    }
    // Si l'utilisateur est authentifié avec Github
    else if (identities.some(identity => identity.provider === 'github')) {
      const githubIdentity = identities.find(identity => identity.provider === 'github');
      if (githubIdentity) {
        username = githubIdentity.identity_data.full_name;
        image = githubIdentity.identity_data.avatar_url;
      }
    }
    // Si l'utilisateur est authentifié avec Email et mot de passe
    else {
      // Si app_metadata existe et contient le champ username
      if (app_metadata && app_metadata.username) {
        username = app_metadata.username;
      }
    }

    return { username, email, image };
  } else {
    // Si la session n'est pas valide ou si l'utilisateur n'est pas connecté, retourner null
    return null;
  }
 */
  export const likeCours = async () =>{
    try{
        const { data, error} = await  supabase
        .from("User_Liked_Lesson")
        .select("*")
        .eq("User_ID",JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)
        .eq("Liked",true)

        if(error){
            console.log(error)
        }
        else{
            return data
        }
    }
    catch(error){
        console.log(error)
    }
}

export const commentCours = async () =>{
    try{
        const {data,error} = await supabase
        .from("User_Comment_Lesson")
        .select("*")
        .eq("User_ID",JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)

        if(error){
            console.log(error)
        }
        else{
            return data
        }
    }
    catch(error){
        console.log(error)
    }
}  

export const fetchSavedCourses = async() =>{
    console.log(JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)

    try{
        const { data,error } = await supabase
            .from("User_Cours")
            .select("*")
            .eq("User_ID",JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)
            .eq("courseSaved",true)

        if(data){
            console.log(data)
            return data
        }
        else{
            console.error(error)
        }
    }
    catch(error){
        console.error(error)

    }
    

}

export const SavedCours = async() =>{
    try{
        const{ data,error } = await supabase
        .from("User_Cours")
        .select("*")
        .eq("User_ID",JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)
        .eq("courseSaved",true)

        if(error){
            console.log(error)
        }else {
            const coursesPromises = data.map(async (element) => {

                    const { data: cour, error } = await supabase
                        .from("Course")
                        .select("*")
                        .eq("CourseID", element.Course_ID)
                        .single()
    
                        if (error) {
                            console.log(error);
                            return null;
                        } else {
                            const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
                            const {lessonCount,courseRating} = await fetchLessonCount(cour.CourseID);
                            return {cour, ...teacherNameAndThumbnail, lessonCount,courseRating};
                        }
                    });
        
                    // Attendre la résolution de toutes les promesses
                    const resolvedData = await Promise.all(coursesPromises);
                    console.log(resolvedData);
                    return resolvedData;
        }

    }
    catch(error){

    }
}

export const CoursLiked = async () => {
    try {
        const { data: likedLessons, error: likedLessonsError } = await supabase
            .from("User_Liked_Lesson")
            .select("*")
            .eq("User_ID", JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)
            .eq("Liked", true);

        if (likedLessonsError) {
            console.log(likedLessonsError);
            return null;
        }

        const lessonPromises = likedLessons.map(async (likedLesson) => {
            const { data: lesson, error: lessonError } = await supabase
                .from("Lesson")
                .select("Chapter_ID")
                .eq("LessonID", likedLesson.Lesson_ID)
                .single();

            if (lessonError) {
                console.log(lessonError);
                return null;
            }

            const { data: chapter, error: chapterError } = await supabase
                .from("Chapter")
                .select("Course_ID")
                .eq("ChapterID", lesson.Chapter_ID)
                .single();

            if (chapterError) {
                console.log(chapterError);
                return null;
            }

            return chapter.Course_ID;
        });

        const courseIDs = await Promise.all(lessonPromises);

        const { data: courses, error: coursesError } = await supabase
            .from("Course")
            .select("*")
            .in("CourseID", courseIDs);

        if (coursesError) {
            console.log(coursesError);
            return null;
        }

        const resolvedData = await Promise.all(courses.map(async (cour) => {
            const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
            const {lessonCount,courseRating} = await fetchLessonCount(cour.CourseID);
            return { cour, ...teacherNameAndThumbnail, lessonCount,courseRating };
        }));

        console.log(resolvedData);
        return resolvedData;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const CoursComment = async () => {
    try {
        const { data: likedLessons, error: likedLessonsError } = await supabase
            .from("User_Comment_Lesson")
            .select("*")
            .eq("User_ID", JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id)

        if (likedLessonsError) {
            console.log(likedLessonsError);
            return null;
        }

        const lessonPromises = likedLessons.map(async (likedLesson) => {
            const { data: lesson, error: lessonError } = await supabase
                .from("Lesson")
                .select("Chapter_ID")
                .eq("LessonID", likedLesson.Lesson_ID)
                .single();

            if (lessonError) {
                console.log(lessonError);
                return null;
            }

            const { data: chapter, error: chapterError } = await supabase
                .from("Chapter")
                .select("Course_ID")
                .eq("ChapterID", lesson.Chapter_ID)
                .single();

            if (chapterError) {
                console.log(chapterError);
                return null;
            }

            return chapter.Course_ID;
        });

        const courseIDs = await Promise.all(lessonPromises);

        const { data: courses, error: coursesError } = await supabase
            .from("Course")
            .select("*")
            .in("CourseID", courseIDs);

        if (coursesError) {
            console.log(coursesError);
            return null;
        }

        const resolvedData = await Promise.all(courses.map(async (cour) => {
            const teacherNameAndThumbnail = await mapIdToNameAndThumbnail(cour.Teacher_ID);
            const {lessonCount,courseRating}= await fetchLessonCount(cour.CourseID);
            return {cour,...teacherNameAndThumbnail,lessonCount,courseRating}
        }));

        console.log(resolvedData);
        return resolvedData;
    } catch (error) {
        console.log(error);
        return null;
    }
};