import { Routes, Route } from 'react-router-dom';
import Welcome from './pages/Welcome';
import MainAuthPage from './pages/MainAuth';
import Error from './pages/Error/noAuth';
import Login from './pages/Login';
import Register from './pages/Register';
import Contact from './pages/Contact/noAuth';
import About from './pages/About/noAuth';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/ForgotPassword/UpdatePassword';
import SuccessRegister from './pages/SuccessRegister';

const RoutesApp = () => {
  return (
    <Routes>
      <Route path='/' element={<Welcome/>} />
      <Route path='/auth/*' element={<MainAuthPage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/register/success' element={<SuccessRegister />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/forgot-password/update' element={<UpdatePassword />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/about' element={<About />} />
      <Route path='*' element={<Error/>} />
    </Routes>
  );
}

export default RoutesApp;
