import React, { } from "react";
import { Link } from 'react-router-dom';
import { supabase } from "../supabase";
import { fetchLessonCount } from "./course.action";

const teacherID = async(name) =>{
    let teacherName = name ? name : sessionStorage.getItem('teacherName');

    const { data: value, error} = await  supabase
        .from("Teacher")
        .select("TeacherID")
        .eq("Name",teacherName)
        .single()

        if(value){
            return value
        }
        else{
            return error
        }
}


export const updateSpeciality = async(specialization,userId) =>{
    
    const { data, error } = await supabase
    .from('users')
    .update({ speciality: specialization.toString() })
    .eq('id', userId.toString())
    .select()

    if(error){
        console.error(error.message)
    }
        
}

export const getTeacherCourse = async(name) =>{
    try{
        
        const value = await teacherID(name)

            const { data, error} = await  supabase
                .from("Course")
                .select("*")
                .eq("Teacher_ID",value.TeacherID)         
                .eq("isPublish",true)
                
                if(data){
                    console.log(data)   
                    const updateData = await Promise.all(data.map(async (cour) =>{
                        const {lessonCount,courseRating} = await fetchLessonCount(cour.CourseID);
                        return {cour,lessonCount,courseRating}
                    }));
                    return updateData;
                }
                else{
                    console.error(error)
                    return error
                }
            }
            catch(error){
                console.error(error)
                return error
            }
            
        }

        export const getVideoLength = async(name) =>{
            try{
                const value = await teacherID(name)
        
                    const { data, error} = await  supabase
                    .from("Tutorial")
                    .select("*")
                    .eq("Teacher_ID",value.TeacherID)
            
                    if(error){
                        console.log(error)
                    }
                    else{
                        return data
                    }
            }
            catch(error){
                console.error(error)
            }
        }

export const likeCours = async (name) =>{
    try{
        const value = await teacherID(name)

            const { data, error} = await  supabase
            .from("User_Cours")
            .select("*")
            .eq("User_ID",value.TeacherID)
            .gt("notation",0)
            .lt("notation",6)
    
            if(error){
                console.log(error)
            }
            else{
                return data
            }
    }
    catch(error){
        console.error(error)
    }
        
    
}

export const commentCours = async () =>{
    try{

        const value = await teacherID()
        
        const {data,error} = await supabase
        .from("User_Comment_Lesson")
        .select("*")
        .eq("User_ID",value.TeacherID)

        if(error){
            console.log(error)
        }
        else{
            return data
        }
    }
    catch(error){
        console.log(error)
    }
}  

export const allTeachers = async() =>{
    try{
    const { data: value, error} = await  supabase
        .from("Teacher")
        .select("*")
        
        if(value){
            console.log(value)   
                    const updateData = await Promise.all(value.map(async (item) =>{
                        const likeCourslength = await likeCours(item.Name)
                        const teacherVideos = await getVideoLength(item.Name)
                        const teacherCours = await getTeacherCourse(item.Name)
                        return {item,likeCourslength,teacherVideos,teacherCours}
                    }));
                    console.log(updateData)
                    return updateData;
        }
        else{
            console.error(error)
            return error
        }
    }
    catch(error){
        console.error(error)
        return error
    }
}

export const CardCourse = (props)=>{

    function showTeacher(){
        console.log(props.teacherName)
        sessionStorage.setItem('teacherName',props.teacherName)
        sessionStorage.setItem('teacherImg',props.teacherImg)
        sessionStorage.setItem('specialization',props.teacherSpecialization)
    }

    function showCour(){
        showTeacher()
        sessionStorage.setItem('imageCourse',props.image)
        sessionStorage.setItem('titleCourse',props.title)
        sessionStorage.setItem('dateCourse',props.date)
        sessionStorage.setItem('chapterNumber',props.chapterNumber)
    }

    function getIdCourse(){
        sessionStorage.setItem("IdCourse",props.id)
      }

    return(
    <>
    <div className="box" onClick={() => getIdCourse()}>
               <div className="thumb">
                  <img src={props.image} alt=""/>
                  <span>{props.chapterNumber} chapters</span>
               </div>
               <h3 className="title">{props.title}</h3>
               <Link to={'/auth/course/details/'+props.id} onClick={() => showCour()} className="inline-btn">view course</Link>
            </div>
        
    </>             
    )
}

export const CardTeacher = (props) =>{

    function showTeacher(){
        console.log(props.teacherName)
        sessionStorage.setItem('teacherName',props.teacherName)
        sessionStorage.setItem('teacherImg',props.teacherThumbnail)
        sessionStorage.setItem('specialization',props.teacherSpecialization)
    }

    return(
        <div class="box">
            <div class="tutor">
            <img src={props.teacherThumbnail} alt="" />
            <div>
            <h3>{props.teacherName}</h3>
            <span>{props.teacherSpecialization}</span>
            </div>
            </div>
            <p>total playlists : <span>{props.playlistLength}</span></p>
            <p>total videos : <span>{props.teacherVideosLength}</span></p>
            <p>total likes : <span>{props.teacherLikesLength}</span></p>
            <Link to={"/auth/teacher/profile"} class="inline-btn" onClick={() => showTeacher()}>view profile</Link>
        </div>
    )
}