import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  useEffect(() => {
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');
    const enableDarkMode = () => {
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }
    if (darkMode === 'enabled') {
      enableDarkMode();
    }
  });

  const navigate = useNavigate()
  const moveUpPage = () => {
    navigate("/forgot-password/update")
  }
  return (
    <>
      <section className="form-container">

        <form action={""} encType="multipart/form-data">
          <h3>Reset Your Password</h3>
          <p>your email <span>*</span></p>
          <input type="email" name="email" placeholder="enter your email" required maxLength={50} className="box" />
          <input type="submit" value="Send Instruction" name="submit" className="btn" onClick={moveUpPage} />
          <Link to="/login" id="forgot-link" style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>Return to Login</Link>
        </form>
      </section>

      <footer className="footer" style={{ position: 'absolute' }}>

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

      </footer>
    </>
  );
}

export default ForgotPassword;
