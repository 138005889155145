import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SuccessRegistration from './SuccessRegistration';
import Dashboard from './Dashboard';
import CoursesDashboard from './Courses';
import AddCourse from './AddCourse';
import Verification from './Verification';
import Error from '../Error/auth';

const TeacherAuthPage = () => {
  return (
    <Routes>
        <Route path='/success' element={<SuccessRegistration />} />
        <Route path='/verification' element={<Verification />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/courses' element={<CoursesDashboard />} />
        <Route path='/dashboard/course/create' element={<AddCourse />} />
        <Route path='/*' element={<Error />} />
      </Routes>
  );
}

export default TeacherAuthPage;
