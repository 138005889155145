import React, { useState,useEffect } from 'react';
import ReactQuill from 'react-quill';
import { supabase } from '../../../supabase';
import { createLesson } from '../../../services/lesson.action';
import { ClipLoader } from 'react-spinners';
import 'react-quill/dist/quill.snow.css';
import { getTeacherChapterByCoursId,getTeacherLessonByChapterId } from '../../../services/chapter.action';

const StepThree = ({ nextStep, prevStep }) => {
  const [isLoading,setIsLoading] = useState(false);
  const [openChapter, setOpenChapter] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPartOfTutorial, setIsPartOfTutorial] = useState(false);
  const [inputType, setInputType] = useState('link'); // 'link' ou 'file'
  const [videoLink, setVideoLink] = useState('');
  const [videoFile, setVideoFile] = useState();
  const [chapterByCoursId,setChapterByCoursId] = useState([])
  const [selectedChapterToAddLesson,setSelectedChapterToAddLesson] = useState("")
  let i = 1
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'blockquote', 'code-block',
    'link', 'image', 'video', 'formula',
    'header', 'list', 'bullet', 'check',
    'script', 'sub', 'super',
    'indent', 'direction',
    'size', 'color', 'background',
    'font', 'align',
  ];

  const module = {
    toolbar: toolbarOptions
  }

  useEffect(() => {

    const getChaptersAndLessons = async () => {
      const chapters = await getTeacherChapterByCoursId();
      const chaptersWithLessons = await Promise.all(chapters.map(async (chapter) => {
        const lessons = await getTeacherLessonByChapterId(chapter.ChapterID);
        return { ...chapter, lessons };
      }));
      setChapterByCoursId(chaptersWithLessons);
    };

    const subscribeToLesson = () => {
      const channel = supabase.channel('custom-insert-channel')
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'Lesson' },
          (payload) => {
            console.log('Change received!', payload);
            setChapterByCoursId((prevChapters) =>
              prevChapters.map((chapter) => {
                if (chapter.ChapterID === payload.new.Chapter_ID) {
                  return {
                    ...chapter,
                    lessons: [...chapter.lessons, payload.new],
                  };
                }
                console.log(chapter)
                return chapter;
              })
            );
          }
        )
        .subscribe();
      return () => {
        supabase.removeChannel(channel);
      };
    };

    getChaptersAndLessons().then(() =>{
      subscribeToLesson()
    });
  }, [selectedChapterToAddLesson,videoFile]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('backVideo');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleVideo = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file); // Vérifiez le fichier sélectionné
    if (file) {
      setVideoFile(file);
    } else {
      alert('Please select a valid video file');
    }
  };

  const createTeacherLesson = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    console.log("Creating lesson with video file:", videoFile); // Vérifiez le fichier avant de créer la leçon
    const result = await createLesson(selectedChapterToAddLesson, title, description, videoFile, videoLink);
    if (result) {
      sessionStorage.removeItem('backVideo');
    } 
    setIsLoading(false)
  };

  const handleChapterToggle = (chapterId) => {
    setOpenChapter(openChapter === chapterId ? null : chapterId);
    setSelectedChapterToAddLesson(chapterId)
  };

  const handleLessonChange = (event) => {
    setSelectedLesson(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (value) => {
    console.log(description)
    setDescription(value);
  };

  const handleIsPartOfTutorialChange = (event) => {
    setIsPartOfTutorial(event.target.value === 'Oui');
  };

  const handleVideoLinkChange = (event) => {
    setVideoLink(event.target.value);
  };

  const handleInputTypeChange = (type,e) => {
    e.preventDefault()
    setInputType(type);
    setVideoLink('');
    setVideoFile(null);
  };

  return (
    
      !isLoading ?(
<form style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', borderBottom: "1px solid var(--black)", paddingBottom: 20, }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1>Courses / <span style={{ color: '#007bff' }}>Add Courses</span></h1>
        </div>
        <h1>Etape <span style={{ color: '#007bff' }}>3</span>/5</h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '60%' }}>
          <div className="chapters">
            {chapterByCoursId.map((chapter) => (
              <div key={chapter.ChapterID} className="chapter" onClick={() => handleChapterToggle(chapter.ChapterID)}>
                <h3>Chapter {i++}: {chapter.Title}</h3>
                {openChapter === chapter.ChapterID && (
                  <div className="lessons">
                    <label htmlFor={`lesson-${chapter.ChapterID}`}>Lesson:</label>
                    <select
                      id={`lesson-${chapter.ChapterID}`}
                      value={selectedLesson}
                      onChange={handleLessonChange}
                      onClick={(e) => e.stopPropagation()} // prevent click event propagation
                      className="custom-select"
                    >
                      <option value="">Select Lesson</option>
                      {chapter.lessons.map((lesson, index) => (
                        <option key={index} value={lesson}>{lesson.Title}</option>
                      ))}
                    </select>
                    <button onClick={createTeacherLesson} type='submit' style={{backgroundColor: "#F39C12", width: "100%", padding: "5px 0", fontSize: 16, cursor: "pointer", borderRadius: 5, margin: "10px 0", color: "var(--white)"}}>Add a new lesson</button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="form-fields">
            <div className="form-field">
              <label htmlFor="title">Title:</label>
              <input id="title" type="text" required style={{width: "100%"}} value={title} onChange={handleTitleChange} />
            </div>
            <div className="form-field">
              <label htmlFor="description">Contenue de la lecon:</label>
              <ReactQuill
                id="description"
                modules={module}
                formats={formats}
                required
                theme='snow'
                value={description}
                onChange={handleDescriptionChange}
                style={{ height: '200px', marginBottom: '80px' }}
              />
            </div>
            <div className="form-field">
              <label htmlFor="isPartOfTutorial">Cette vidéo contient des tutoriels:</label>
              <div className="radio-group">
                <label>
                  <input
                    id="isPartOfTutorial"
                    type="radio"
                    value="Oui"
                    checked={isPartOfTutorial}
                    onChange={handleIsPartOfTutorialChange}
                  />
                  Oui
                </label>
                <label>
                  <input
                    id="isPartOfTutorial"
                    type="radio"
                    value="Non"
                    checked={!isPartOfTutorial}
                    onChange={handleIsPartOfTutorialChange}
                  />
                  Non
                </label>
              </div>
            </div>
            <div className="form-field">
              <label>Video Input:</label>
              <div>
                <button
                  onClick={(e) => handleInputTypeChange('link',e)}
                  style={{ backgroundColor: inputType === 'link' ? '#007bff' : '#f0f0f0', color: inputType === 'link' ? 'white' : 'black' }}
                >
                  Online Link
                </button>
                <button
                  onClick={(e) => handleInputTypeChange('file',e)}
                  style={{ backgroundColor: inputType === 'file' ? '#007bff' : '#f0f0f0', color: inputType === 'file' ? 'white' : 'black' }}
                >
                  Upload File
                </button>
              </div>
              {inputType === 'link' ? (
                <div>
                  <label htmlFor="videoLink">Video Link:</label>
                  <input
                    id="videoLink"
                    type="text"
                    value={videoLink}
                    onChange={handleVideoLinkChange}
                    placeholder="Enter video URL"
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="videoFile">Upload Video:</label>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleVideo}
                  />
                  {videoFile && <p>Selected file: {videoFile.name}</p>}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={() =>{prevStep()
              setSelectedChapterToAddLesson('')
            }} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Previous</button>
            <button onClick={() =>{nextStep()
              setSelectedChapterToAddLesson('')
            }} style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
          </div>
        </div>
        <div style={{ width: '35%', padding: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
          <h2>Preview</h2>
          <div style={{ width: '100%', height: '200px', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>Preview</div>
        </div>
      </div>
    </form>
      ):(
        <ClipLoader
          color={'#2196F3'}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )
    
    
  );
};

export default StepThree;
