import React from 'react';
import { createChapterForCourse } from '../../../services/addCourse.action';
import { useState,useEffect } from 'react';
import { ClipLoader } from 'react-spinners';


const StepTwo = ({ nextStep, prevStep }) => {
  const [ title,setTitle ] = useState()
  const [ description,setDescription ] = useState('')
  const [ isLoading,setIsLoading ] = useState(false);

  const createChapForCourse = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    const result = await createChapterForCourse(title,description)

      if(result){
        alert("Chapter was create with success")
        setIsLoading(false)

      }else{
        alert("Chapter was not created")
        setIsLoading(false)
      }

  }

  useEffect(() =>{
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('backImageChange');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  })

  return (
    !isLoading?(
    <form>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 10}}>
            <h1>Courses / <span style={{ color: '#007bff' }}>Add Courses</span></h1>
            <h1>Etape <span style={{ color: '#007bff' }}>2</span>/5</h1>
          </div>
          <p style={{fontSize: "1.4rem", marginBottom: 10}}>Veuillez ajouter les chapitres de votre cours.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '60%' }}>
              <div style={{ marginBottom: '20px' }}>
                <label style={{fontSize: 16}}>Titre du chapitre :</label>
                <input type="text" onChange={(e) => setTitle(e.target.value)} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }} />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label style={{fontSize: 16}}>Description :</label>
                <textarea onChange={(e) => setDescription(e.target.value)} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none' }} rows="4"></textarea>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <button type='submit'  onClick={createChapForCourse} style={{backgroundColor: "#F39C12", width: "100%", padding: "10px 0", fontSize: 16, cursor: "pointer", borderRadius: 5, margin: "10px 0", color: "var(--white)"}}>Ajouter un autre chapitre</button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={prevStep} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Previous</button>
                <button onClick={nextStep} style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
              </div>
            </div>
            <div style={{ width: '35%', padding: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
              <h2>Preview</h2>
              <div style={{ width: '100%', height: '200px', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>Preview</div>
            </div>
          </div>
    </form>
    ):(
      <ClipLoader
      color={'#2196F3'}
      loading={isLoading}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
    )
  );
};

export default StepTwo;
