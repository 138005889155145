import React, { useState, useEffect } from 'react';
import { getTeacherChapterByCoursId, getTeacherQuestionsByChapterId } from '../../../services/chapter.action';
import { deleteQuizz, createQuizz } from '../../../services/quiz.action';
import { ClipLoader } from 'react-spinners';

const StepFour = ({ nextStep, prevStep }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [openChapter, setOpenChapter] = useState(null);
  const [customQuestions, setCustomQuestions] = useState({});
  const [currentPreview, setCurrentPreview] = useState(1);

  useEffect(() => {
    const getChapters = async () => {
      const chapters = await getTeacherChapterByCoursId();
      const chaptersWithQuestions = await Promise.all(
        chapters.map(async (chapter) => {
          const questions = await getTeacherQuestionsByChapterId(chapter.ChapterID);
          return { ...chapter, questions };
        })
      );
      setChapters(chaptersWithQuestions);

      const questionsByChapter = chaptersWithQuestions.reduce((acc, chapter) => {
        acc[chapter.ChapterID] = chapter.questions;
        return acc;
      }, {});
      setCustomQuestions(questionsByChapter);
    };

    getChapters();
  }, []);

  const handleChapterToggle = (chapterId) => {
    setOpenChapter(openChapter === chapterId ? null : chapterId);
  };

  const handleQuestionChange = (chapterId, questionId, field, value) => {
    setCustomQuestions((prevQuestions) => ({
      ...prevQuestions,
      [chapterId]: prevQuestions[chapterId].map(q =>
        q.QuestionID === questionId ? { ...q, [field]: value } : q
      )
    }));
  };

  const handleOptionChange = (chapterId, questionId, index, value) => {
    setCustomQuestions((prevQuestions) => ({
      ...prevQuestions,
      [chapterId]: prevQuestions[chapterId].map(q =>
        q.QuestionID === questionId ? { ...q, Options: q.Options.map((opt, i) => i === index ? value : opt) } : q
      )
    }));
  };

  const removeQuestion = (chapterId, questionId) => {
    setCustomQuestions((prevQuestions) => ({
      ...prevQuestions,
      [chapterId]: prevQuestions[chapterId].filter(q => q.QuestionID !== questionId)
    }));
  };

  const addQuestion = (e, chapterId) => {
    e.preventDefault();
    setCustomQuestions((prevQuestions) => ({
      ...prevQuestions,
      [chapterId]: [
        ...prevQuestions[chapterId],
        {
          QuestionID: prevQuestions[chapterId].length + 1,
          QuestionText: '',
          Options: ['', '', '', ''],
          CorrectAnswer: '',
          Chapter_ID: chapterId,
        }
      ]
    }));
    if(openChapter !== chapterId)
      handleChapterToggle(chapterId)
  };

  const handleAnswerChange = (chapterId, questionId, value) => {
    setCustomQuestions((prevQuestions) => ({
      ...prevQuestions,
      [chapterId]: prevQuestions[chapterId].map(q =>
        q.QuestionID === questionId ? { ...q, CorrectAnswer: value } : q
      )
    }));
  };

  const uploadQuestions = async () => {
    setIsLoading(true);
    const isDelete = await deleteQuizz(Object.values(customQuestions).flat());

    if (isDelete) {
      const isCreated = await createQuizz(Object.values(customQuestions).flat());
      if (isCreated) {
        alert("Questions have been successfully created");
        setIsLoading(false);
      } else {
        alert("Questions were not created");

        setIsLoading(false);
      }
    } else {
      alert("Questions were not deleted");
      setIsLoading(false);
      
    }
  };

  return (
    !isLoading ? (
      <form style={{ padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '60%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', borderBottom: "1px solid var(--black)", paddingBottom: 20 }}>
            <h1>Courses / <span style={{ color: '#007bff' }}>Add Courses</span></h1>
            <h1>Etape <span style={{ color: '#007bff' }}>4</span>/5</h1>
          </div>
          <p>Veuillez ajouter la/les question(s) de vos chapitres</p>
          {chapters.map((chapter, index) => (
            <div key={chapter.ChapterID} className="chapter">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>Chapter {index + 1}: {chapter.Title}</h3>
                <span style={{ cursor: 'pointer' }} onClick={() => handleChapterToggle(chapter.ChapterID)}>▼</span>
              </div>
              {openChapter === chapter.ChapterID && (
                <div>
                  {customQuestions[chapter.ChapterID]?.map((question, qIndex) => (
                    <div key={question.QuestionID} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3>Question {qIndex + 1}</h3>
                        <button onClick={() => removeQuestion(chapter.ChapterID,question.QuestionID)}
                          style={{ backgroundColor: "red", color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}>
                          Remove question
                        </button>                      
                      </div>
                      <input
                        type="text"
                        placeholder="Titre de la question"
                        value={question.QuestionText}
                        onChange={(e) => handleQuestionChange(chapter.ChapterID, question.QuestionID, 'QuestionText', e.target.value)}
                        style={{ width: '100%', marginBottom: '10px' }}
                      />
                      <h4>Options</h4>
                      {question.Options.map((option, index) => (
                        <input
                          key={index}
                          type="text"
                          placeholder={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) => handleOptionChange(chapter.ChapterID, question.QuestionID, index, e.target.value)}
                          style={{ width: '100%', marginBottom: '5px' }}
                        />
                      ))}
                      <h4>Réponses</h4>
                      <select style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }}
                        value={question.CorrectAnswer}
                        onChange={(e) => handleAnswerChange(chapter.ChapterID, question.QuestionID, e.target.value)}>
                        <option value="">Options</option>
                        {question.Options.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              )}
              
              <div className="heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={(e) => addQuestion(e, chapter.ChapterID)}
                  style={{ backgroundColor: '#3498db', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}>
                  + Question
                </button>
                {
                  openChapter === chapter.ChapterID && (
                    customQuestions[chapter.ChapterID]?.length > 0 &&(
                      <button onClick={uploadQuestions}
                      style={{ backgroundColor: "#F39C12", color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}>
                      Update chapter question
                    </button>
                    )
                  )
                }
              </div>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <button onClick={prevStep} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Preview</button>
            <button type="submit" onClick={() => nextStep()} style={{ backgroundColor: 'var(--main-color)', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
          </div>
        </div>
        <div style={{ width: '35%', padding: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
          <h2>Preview</h2>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h3>Titre De La Question {currentPreview}</h3>
            {['Option 1', 'Option 2', 'Option 3', 'Option 4'].map((option, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '20px', height: '20px', borderRadius: '50%', border: '2px solid #ddd', marginRight: '10px' }}></div>
                <span>{option}</span>
              </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              {[1, 2, 3, 4].map((num) => (
                <div key={num} style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: num === currentPreview ? '#3498db' : '#ddd', margin: '0 5px' }}></div>
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Preview</button>
              <button style={{ backgroundColor: '#3498db', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
            </div>
          </div>
        </div>
      </form>
    ) : (
      <ClipLoader
        color={'#2196F3'}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
  );
};

export default StepFour;




// const [selectedChapter,setSelectedChapter] = useState("")
