import React from 'react'

const Pagination = ({ coursPerPage,totalCours,paginate }) => {
    
    const pageNumbers = [];
    for(let i=1;i<=Math.ceil(totalCours/coursPerPage);i++){
        pageNumbers.push(i)
    }
    
    return(
        <nav>
            <div className="pagination" style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                {
                    pageNumbers.map(number => (
                        <button onClick={() => paginate(number)} style={{ backgroundColor: '#fff',cursor:'pointer', color: '#007bff', border: '1px solid #ddd', padding: '5px 10px' }}>{number}</button>
                    ))
                }
            </div>
        </nav>
    )
}

export default Pagination