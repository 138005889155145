import React, { useState, useEffect } from 'react';
import { updateChapterForCourse } from '../../../services/chapter.action';
import { ClipLoader } from 'react-spinners';
import { supabase } from '../../../supabase';
import { removeChapterById } from '../../../services/chapter.action';
import { createChapterForCourse } from '../../../services/addCourse.action';
import { getTeacherChapterByCoursId } from '../../../services/chapter.action';

const StepTwoUpdate = ({ nextStep, prevStep }) => {
  const [ title,setTitle ] = useState()
  const [ description,setDescription ] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [openChapter, setOpenChapter] = useState(null);

  // Gérer les états de chaque chapitre individuellement
  const [chapterData, setChapterData] = useState({});

  useEffect(() => {
    const getChapters = async () => {
      const chapters = await getTeacherChapterByCoursId();
      setChapters(chapters);

      // Initialiser chapterData pour chaque chapitre
      const initialData = {};
      chapters.forEach(chapter => {
        initialData[chapter.ChapterID] = {
          title: chapter.Title || '',
          description: chapter.Description || '',
        };
      });
      setChapterData(initialData);
    };

    getChapters().then(() =>{
      subscribeToChapter()
    })
  }, [chapters]);

  const handleTitleChange = (chapterId, value) => {
    setChapterData(prevData => ({
      ...prevData,
      [chapterId]: {
        ...prevData[chapterId],
        title: value,
      },
    }));
  };

  const handleDescriptionChange = (chapterId, value) => {
    setChapterData(prevData => ({
      ...prevData,
      [chapterId]: {
        ...prevData[chapterId],
        description: value,
      },
    }));
  };

  const subscribeToChapter = () => {
    const channel = supabase.channel('custom-insert-channel')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'Chapter' },
        (payload) => {
          console.log('Change received!', payload);
          setChapters((prevChapters)=>{
            return [...prevChapters,payload.new]
          });
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(channel);
    };
  };

  const createChapForCourse = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    const result = await createChapterForCourse(title,description)

      if(result){
        alert("Chapter was create with success")
        setIsLoading(false)

      }else{
        alert("Chapter was not created")
        setIsLoading(false)
      }

  }

  const updateChapForCourse = async (e, chapterId) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(chapterData)
    const result = await updateChapterForCourse(
      chapterId,
      chapterData[chapterId].title,
      chapterData[chapterId].description
    );

    if (result) {
      alert("Chapter was updated successfully");
    } else {
      alert("Chapter was updated not created");
    }
    setIsLoading(false);
  };

  const handleChapterToggle = (chapterId) => {
    setOpenChapter(openChapter === chapterId ? null : chapterId);
  };

  const removeChapter = async(e,chapterId) => {
    e.preventDefault();
    setIsLoading(true);

    const isDelete = await removeChapterById(chapterId)

    if (isDelete) {
      alert("Chapter was deleted successfully");
    } else {
      alert("Chapter was not deletd");
    }
    setIsLoading(false);
  };

  return (
    !isLoading ? (
      <form>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid var(--black)", paddingBottom: 20, marginBottom: 10 }}>
          <h1>Courses / <span style={{ color: '#007bff' }}>Update Courses</span></h1>
          <h1>Etape <span style={{ color: '#007bff' }}>2</span>/5</h1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '60%' }}>
            {chapters.map((chapter, index) => (
              <div key={chapter.ChapterID} className="chapter">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h3>Chapter {index + 1}: {chapter.Title}</h3>
                  <span style={{ cursor: 'pointer' }} onClick={() => handleChapterToggle(chapter.ChapterID)}>▼</span>
                </div>
                {openChapter === chapter.ChapterID && (
                  <>
                    <div style={{ marginBottom: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <label style={{ fontSize: 16 }}>Titre du chapitre :</label>
                      <button onClick={(e) => removeChapter(e,chapter.ChapterID)}
                          style={{ backgroundColor: "red", color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' }}>
                          Delete chapter
                        </button> 
                        </div>
                      <input
                        type="text"
                        value={chapterData[chapter.ChapterID]?.title || ''}
                        onChange={(e) => handleTitleChange(chapter.ChapterID, e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }}
                      />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <label style={{ fontSize: 16 }}>Description :</label>
                      <textarea
                        value={chapterData[chapter.ChapterID]?.description || ''}
                        onChange={(e) => handleDescriptionChange(chapter.ChapterID, e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none' }}
                        rows="4"
                      ></textarea>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <button
                        type='submit'
                        onClick={(e) => updateChapForCourse(e, chapter.ChapterID)}
                        style={{ backgroundColor: "#F39C12", width: "100%", padding: "10px 0", fontSize: 16, cursor: "pointer", borderRadius: 5, margin: "10px 0", color: "var(--white)" }}>
                        Update the chapter
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
            <p style={{ fontSize: "1.4rem", marginBottom: 10 }}>Veuillez ajouter les chapitres de votre cours.</p>
              <div style={{ marginBottom: '20px' }}>
                <label style={{fontSize: 16}}>Titre du chapitre :</label>
                <input type="text" onChange={(e) => setTitle(e.target.value)} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px' }} />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label style={{fontSize: 16}}>Description :</label>
                <textarea onChange={(e) => setDescription(e.target.value)} style={{ width: '100%', padding: '10px', marginTop: '5px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none' }} rows="4"></textarea>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <button type='submit'  onClick={createChapForCourse} style={{backgroundColor: "#F39C12", width: "100%", padding: "10px 0", fontSize: 16, cursor: "pointer", borderRadius: 5, margin: "10px 0", color: "var(--white)"}}>Ajouter un autre chapitre</button>
              </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={prevStep} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Previous</button>
              <button onClick={nextStep} style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Next</button>
            </div>
          </div>
          <div style={{ width: '35%', padding: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
            <h2>Preview</h2>
            <div style={{ width: '100%', height: '200px', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>Preview</div>
          </div>
        </div>
      </form>
    ) : (
      <ClipLoader
        color={'#2196F3'}
        loading={isLoading}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
  );
};

export default StepTwoUpdate;
