import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  useEffect(() => {
    let body = document.body;
    // let main = document.querySelector('.main');
    let darkMode = localStorage.getItem('dark-mode');
    const enableDarkMode = () => {
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }
    if (darkMode === 'enabled') {
      enableDarkMode();
    }
  });
  return (
    <section className="error full">
      <div className="content">
        <h3>404</h3>
        <h1>Page not found</h1>
        <p>Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="button-container">
          <Link className='link' to={"/"}>Go back home</Link>
          <Link className='link' to={"/auth/contact"}>Contact support <span aria-hidden="true">&rarr;</span></Link>
        </div>
      </div>
    </section>
  );
}

export default Error;
