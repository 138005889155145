import React from 'react';
import { useState } from 'react';
import { submitCourse } from '../../../services/course.action';
import { ClipLoader } from 'react-spinners';

const StepFive = ({ prevStep }) => {
  const [isLoading,setIsLoading] = useState(false);

  const handleSubmit = async() =>{
    setIsLoading(true)
    const isSubmit = await submitCourse(localStorage.getItem('teacherManageCourseId'))

    if(isSubmit){
      alert('Course sucessfully submit')
      setIsLoading(false)
    }else{
      alert('Course not submit')
      setIsLoading(false)
    }
    
  }


  return (
    !isLoading ?(
<form style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom: "1px solid var(--black)", paddingBottom: 20 }}>
        <h1 style={{ fontSize: '24px', margin: 0 }}>
          Courses / <span style={{ color: '#007bff' }}>Add Courses</span>
        </h1>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Etape <span style={{ color: '#007bff' }}>5</span>/5</span>
      </div>
      
      <p style={{ fontSize: '16px', marginBottom: '20px' }}>Résumé et soumission du cours.</p>
      
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '40px' }}>
        <div style={{ width: '60%' }}>
          <div style={{ marginBottom: '30px', backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px' }}>
            <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Résumé :</h2>
            <p style={{ margin: '10px 0' }}><strong>Titre du cours :</strong>{localStorage.getItem('CourseTeacherTitle')} </p>
            <p style={{ margin: '10px 0' }}><strong>Prix du cours :</strong>{localStorage.getItem("CourseTeacherPrice")} XAF</p>
            <p style={{ margin: '10px 0' }}><strong>Catégorie :</strong>{localStorage.getItem("CourseTeacherCategorie")}</p>
            <p style={{ margin: '10px 0' }}><strong>Description :</strong>{localStorage.getItem("CourseTeacherDescription")}</p>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button 
              onClick={prevStep} 
              style={{ 
                backgroundColor: '#ccc', 
                color: '#333', 
                padding: '10px 20px', 
                border: 'none', 
                borderRadius: '5px', 
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s'
              }}
            >
              Previous
            </button>
            <button 
              onClick={handleSubmit}
              style={{ 
                backgroundColor: '#007bff', 
                color: 'white', 
                padding: '10px 20px', 
                border: 'none', 
                borderRadius: '5px', 
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s'
              }}
            >
              Submit your course in Itestudy
            </button>
          </div>
        </div>
        
        <div style={{ width: '35%' }}>
          <div style={{ 
            padding: '20px', 
            border: '1px solid #ddd', 
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Preview</h2>
            <div style={{ 
              width: '100%', 
              height: '200px', 
              backgroundColor: '#f5f5f5', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              borderRadius: '5px',
              color: '#666',
              fontSize: '18px'
            }}>
              Preview
            </div>
          </div>
        </div>
      </div>
    </form>
    ):(
        <ClipLoader
          color={'#2196F3'}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
    )
  );
};

export default StepFive;