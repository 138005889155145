import React, { useState } from 'react';
import { supabase } from '../supabase';
import emailjs from '@emailjs/browser';

export const handleUpload = async (userId, image) => {
    if (!image) return;
  
    const response = await fetch(image);
    const blob = await response.blob();
    const filePath = `${userId}/${new Date().getTime()}.${blob.type.split('/')[1]}`;
  
    const { data, error } = await supabase.storage
      .from('userCertificationAndCNI')
      .upload(filePath, blob);
      
    if (error) {
      console.error('Error uploading image:', error);
    } else {
      const publicUrl = supabase.storage
        .from('userCertificationAndCNI')
        .getPublicUrl(filePath).data.publicUrl;
  
      const { data: dbData, error: dbError } = await supabase
        .from('imagesDocument')
        .insert([{ user_id: userId, url: publicUrl }]);
  
      if (dbError) {
        console.error('Error inserting image into database:', dbError);
      } else {
        console.log('Image uploaded and saved successfully:', publicUrl);
      }
    }
  };

export const fetchImagesForUser = async (userId) => {
    const { data, error } = await supabase
      .from('imagesDocument')
      .select('*')
      .eq('user_id', userId);
  
    if (error) {
      console.error('Error fetching images:', error);
    } else {
      console.log('User images:', data);
      return data;
    }
  };



  /**            <input type="text" placeholder="enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required maxLength={50} className="box"/>
            <input type="email" placeholder="enter your email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required maxLength={50} className="box"/>
            <input type="hidden" name="specialization" value={specialization} className="box" />
            <input type="hidden" name="yearsExperience" value={yearsExperience} className="box" />
            <input type="text" placeholder="enter your subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required maxLength={50} className="box"/>
            <textarea name="msg" className="box" placeholder="enter your message" value={message} onChange={(e) => setMessage(e.target.value)} required maxLength={1000} cols="30" rows="10"></textarea>
            <input type="submit" value="send message" className="inline-btn" name="submit"/> */