import React, {  } from 'react';
import AddCourseSteps from './AddCourseSteps';

const AddCourse = () => {
  return (
    <>
      <AddCourseSteps />
      <br /><br /><br /><br /><br />
    </>
  );
};

export default AddCourse;
