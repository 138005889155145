import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { loginUser } from "../../services/auth/EmailAndPassword";
import { signInWithGoogle } from "../../services/auth/Google";
import { signInWithGithub } from "../../services/auth/Github";
import { ClipLoader } from "react-spinners";

const Login = () => {
  useEffect(() => {
    let toggleBtn = document.getElementById('toggle-btn-login');
    let body = document.body;
    let darkMode = localStorage.getItem('dark-mode');

    const enableDarkMode = () => {
      toggleBtn.classList.replace('fa-sun', 'fa-moon');
      body.classList.add('dark');
      localStorage.setItem('dark-mode', 'enabled');
    }

    const disableDarkMode = () => {
      toggleBtn.classList.replace('fa-moon', 'fa-sun');
      body.classList.remove('dark');
      localStorage.setItem('dark-mode', 'disabled');
    }

    if (darkMode === 'enabled') {
      enableDarkMode();
    }

    toggleBtn.addEventListener('click', () => {
      darkMode = localStorage.getItem('dark-mode');
      if (darkMode === 'disabled') {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });
  }, []);

  const navigate = useNavigate()

  const backNavigate = () => {
    navigate(-1)
  }

  //Users auth features

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleCheckboxChange = () => {
      setRemember(!remember); // Inverse l'état actuel de la case à cocher
    };

    const HandleSubmit = async (e) => {
      e.preventDefault();
      // Faire quelque chose avec les données du formulaire
      setIsLoading(true)
    
      // Appeler la fonction loginUser avec les données du formulaire
      const loginResult = await loginUser(email, password, remember);
      console.log(loginResult);
    
      if (loginResult.success) {
        // La connexion a réussi, vous pouvez rediriger l'utilisateur ou effectuer d'autres opérations nécessaires.
        // Mettre à jour l'état de connexion
        setIsLoading(false)
        navigate('/auth/home')
      } else {
        // La connexion a échoué, affichez l'erreur ou prenez d'autres mesures nécessaires.
        setError(loginResult.error)
        setIsLoading(false)
        setShowAlert(true)
      }
    };
    const HandleGoogleAuth = async () => {
      const loginResult = await signInWithGoogle();
      if (loginResult.success) {
        // TODO: nothing to do here
      } else {
        // Afficher un message d'erreur
        setError("Erreur lors de la connexion avec Google : " + loginResult.error);
        setShowAlert(true)
      }
    };

    const HandleGithubAuth = async () =>{
      setIsLoading(true);

      const loginResult = await signInWithGithub();
      console.log(loginResult);

      if (loginResult.success) {
        // TODO: nothing to do
      }else{
        setError(loginResult.error)
        setShowAlert(true)
      }
    }

  return (
    !isLoading ? (
      <>
        <header className="header">

        <section className="flex">

          <Link to={'/'} className="logo" translate='no'>ITESTUDY</Link>

          <div style={{ fontSize: 40 }} id="auth-nav-icon" className="fas fa-close" onClick={backNavigate}></div>

        </section>

        </header>

        <section className="form-container">

        <form onSubmit={HandleSubmit} >
          <h3>Login to Your Account</h3>
          {showAlert && (
            <div className="alert">
              <p>{error}</p>
            </div>
          )}
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
            <GoogleLoginButton onClick={() => HandleGoogleAuth()} text="Google" align="center" style={{}} />
            <GithubLoginButton onClick={() => HandleGithubAuth()} text="Github" align="center" style={{}} />
          </div>
          <div className="connect-with">
            <div className="horizontal-line"></div>
            <p style={{ fontSize: 20 }}>Or Login With</p>
            <div className="horizontal-line"></div>
          </div>
          <p>your email <span>*</span></p>
          <input type="email" name="email" placeholder="enter your email" onChange={(e) => setEmail(e.target.value)} required maxLength={50} className="box" />
          <p>your password <span>*</span></p>
          <input type="password" name="pass" placeholder="enter your password" onChange={(e) => setPassword(e.target.value)} required maxLength={20} className="box" />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }} className="remember-checkbox">
              <input type="checkbox" name="remember" checked={remember} onChange={handleCheckboxChange} id="remember" />
              <label htmlFor="remember">Remember</label>
            </div>
            <Link to="/forgot-password" id="forgot-link">Forgot Password</Link>
          </div>
          <input type="submit" value="login" name="submit" className="btn" />
          <Link to={'/register'} id="forgot-link" style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>Don't have an account?</Link>
        </form>
        <div id="toggle-btn-login" className="fas fa-sun"></div>
        </section>

        <footer className="footer">

        &copy; copyright @ 2024 by <span>itestudy</span> | all rights reserved!

        </footer>
      </>
      
    ): (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <ClipLoader
            color={'#2196F3'}
            loading={isLoading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
      </div>
      )
  );
}

export default Login;