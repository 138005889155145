export const commentaire=[
    {
     userName:"Junior",
     content:"Contenu du commentaire",
     time:"20 Minutes ago"
   }, 
   {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  },  
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  },  
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 
  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 

  {
    userName:"Junior",
    content:"Contenu du commentaire",
    time:"20 Minutes ago"
  }, 


]