import React from "react";
import { useState } from "react";
import { supabase } from "../supabase";



export const fetchCommentById = async() => {
    let IdLesson  = sessionStorage.getItem("ActualLessonId")

    try{
        const { data, error } = await supabase
        .from('User_Comment_Lesson')
        .select("*")
        .eq('Lesson_ID', IdLesson)
    
        if(data){
            return data
        }
        else{
            console.error(error)
        }
    }
    catch(error){
        console.error(error)
    }     
}


export const CardComment = (props) =>{

    const [isEditing, setIsEditing] = useState(false);
    const [edit,setEdit] = useState(false)
    const userId = JSON.parse(localStorage.getItem("sb-bltsqrylosfslztpomhw-auth-token")).user.id
    const [editCommentId, setEditCommentId] = useState(null);
    const [editedComment,setEditedComment] = useState(props.comment)

    const verifEdit = (commentId) => {
        const openForm = sessionStorage.getItem("openForm");
        if (props.idUser === userId) {
            if (editCommentId === commentId && isEditing) {
                // Si le commentaire est déjà en cours d'édition et le formulaire est ouvert, ferme-le
                setIsEditing(false);
                setEditedComment(props.comment)
                setEditCommentId(null);
                sessionStorage.setItem("openForm", false);
            } else if (openForm !== "true") {
                // Sinon, ouvre le formulaire pour le commentaire cliqué
                setIsEditing(true);
                setEdit(false)
                setEditCommentId(commentId);
                sessionStorage.setItem("openForm", true);
            }
        }
    };

    const handleEdit = () => {
        setEdit(!edit);
      };

      const handleChange = (e) => {
        setEditedComment(e.target.value);
      };

    //   const handleTextareaClick = (e) => {
        
    //     if (!isEditing) {
    //         setIsEditing(true);
    //         setEdit(false);
    //     }
    //     e.stopPropagation();
    // };
    

    // const handleEnterClick = (event) => {
    //     if (event.key === "Enter") {
    //         if (!isEditing) {
    //             setIsEditing(true);
    //             setEdit(false);
    //         }
    //         console.log("Enter")
    //     }
    // };

    const handleKeyDown = async(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            // Réagir à l'appui sur la touche "Entrée"
            // Par exemple, vous pouvez soumettre le formulaire ou effectuer une autre action
            console.log("Touche Entrée pressée");
            
            const { data, error } = await supabase
            .from('User_Comment_Lesson')
            .update({ CommentText: editedComment.toString() })
            .eq('id', props.id)
            .select()
        
            setEdit(!edit)
        }
    };

    const deleteComment = async() => {
        try{
            const { error } = await supabase
        .from('User_Comment_Lesson')
        .delete()
        .eq('id', props.id)
        console.log("suppression effectue")
        }
        catch(error){
            console.log(error)
        }
        
    }

    return(
        <div className="box" onClick={() => verifEdit(props.id)}>
            <div className="user">
            <img src={props.imgUser} alt="" />
            <div>
                <h3>{props.userName}</h3>
                <span>{props.date.substring(0,10)}</span>
            </div>
            </div>
            <div className="comment-box" style={{ position: 'relative' }}>
                     {edit ? (
                        <textarea
                           value={editedComment}
                           onClick={(e) =>
                            e.stopPropagation()
                           }
                           onKeyDown={handleKeyDown}
                           onChange={handleChange}
                           maxLength={1000}
                           style={{ backgroundColor: 'transparent', resize: 'both',color: "var(--black)"}}
                           cols="30"
                           rows="10"
                        ></textarea>
                     ) : (
                        props.comment
                     )}
                  </div>
            {editCommentId === props.id && isEditing && (
                <form action="" className="flex-btn">
                    <input type="submit" value="edit comment" name="edit_comment" className="inline-option-btn" onClick={handleEdit} />
                    <button type="button" name="delete_comment" className="inline-delete-btn" onClick={() => deleteComment()} >delete comment</button>
                </form>
            )}
            
     </div>
     
    )
}