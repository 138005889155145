import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleUpload } from '../../services/user.action';
import { ClipLoader } from 'react-spinners';
import { getUserSession,getUserImage } from '../../services/user.action';
import './Update.css'; // Assurez-vous de créer ce fichier CSS

const Update = () => {
  const [isLoading2, setIsLoading2] = useState(false);
  const [userImage,setUserImage] = useState("")
  const navigate = useNavigate()
  const [user, setUser] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [userSession, setUserSession] = useState({});
  const [newImage,setNewImage] = useState("");

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const userData = await getUserSession();
        if (userData) {
          setUser(userData);
        } else {
          console.log('No user data found!');
        }
      } catch (error) {
        console.error('Error fetching user session:', error);
      }
    };

    const getImageUser = async() =>{
      const image = await getUserImage()
      const userSession = await getUserSession()
      setUserSession(userSession)
      setUserImage(image)
    }

    fetchUserSession();
    getImageUser()
  }, []);

const handleImageChange = async(e) => {
  setIsLoading2(true)
  const file = e.target.files[0];
  console.log(file)
  if (file && file.type.startsWith('image/')) {
      const newImage = [URL.createObjectURL(file)];
      setNewImage(newImage);
      let uploadImgVal = await handleUpload(newImage)
      if(uploadImgVal){
        console.log("Profil photo successfull upload")
        setIsLoading2(false)
      }
      else{
        console.log("Profil photo upload echec")
        setIsLoading2(false)
      }
      // sessionStorage.setItem('backImage', JSON.stringify(newImage));
  }
};

const handleClick = () => {
  document.getElementById('fileInput').click();
};

  return (
    <>
      <section className="form-container">
        <form action="" encType="multipart/form-data">
          <h3>update profile</h3>
          <div className="circular-upload">
            <label htmlFor="profile-pic" className="circular-upload-label">
            {!isLoading2 ? (
            
            <div>
              {
                newImage ? (
                  <>
                  <img src={newImage} alt="Do you want to change your profil photo?" onClick={handleClick}/>
                  <input type="file" style={{ display: 'none' }}  id="fileInput" accept="image/*" onChange={handleImageChange} />
                  </>
                ):(
                  <>
                  <img src={userImage || userSession['image'] } alt="Do you want to change your profil photo?" onClick={handleClick}/>
                  <input type="file" style={{ display: 'none' }}  id="fileInput" accept="image/*" onChange={handleImageChange} />
                  </>
                )
              }
            </div>
        ):(
          <div className="upload-icon">+</div>
        )
      }
            </label>
            <input
              type="file"
              id="profile-pic"
              accept="image/*"
              onChange={handleImageChange}
              className="circular-upload-input"
            />
          </div>
          <p>update name</p>
          <input type="text" name="name" value={user.username} placeholder="shaikh anas" maxLength={50} className="box"/>
          <p>update email</p>
          <input type="email" name="email" value={user.email} placeholder="shaikh@gmail.come" maxLength={50} className="box"/>
          <p>previous password</p>
          <input type="password" name="old_pass" placeholder="enter your old password" maxLength={20} className="box"/>
          <p>new password</p>
          <input type="password" name="new_pass" placeholder="enter your old password" maxLength={20} className="box"/>
          <p>confirm password</p>
          <input type="password" name="c_pass" placeholder="confirm your new password" maxLength={20} className="box"/>

          <input type="submit" value="update profile" name="submit" className="btn" onClick={() => {navigate('/auth/profile')}}/>
        </form>
      </section>
    </>
  );
}

export default Update;